/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const VRDAVis = $root.VRDAVis = (() => {

    /**
     * Namespace VRDAVis.
     * @exports VRDAVis
     * @namespace
     */
    const VRDAVis = {};

    VRDAVis.Point = (function() {

        /**
         * Properties of a Point.
         * @memberof VRDAVis
         * @interface IPoint
         * @property {number|null} [x] Point x
         * @property {number|null} [y] Point y
         * @property {number|null} [z] Point z
         */

        /**
         * Constructs a new Point.
         * @memberof VRDAVis
         * @classdesc Represents a Point.
         * @implements IPoint
         * @constructor
         * @param {VRDAVis.IPoint=} [properties] Properties to set
         */
        function Point(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Point x.
         * @member {number} x
         * @memberof VRDAVis.Point
         * @instance
         */
        Point.prototype.x = 0;

        /**
         * Point y.
         * @member {number} y
         * @memberof VRDAVis.Point
         * @instance
         */
        Point.prototype.y = 0;

        /**
         * Point z.
         * @member {number} z
         * @memberof VRDAVis.Point
         * @instance
         */
        Point.prototype.z = 0;

        /**
         * Creates a new Point instance using the specified properties.
         * @function create
         * @memberof VRDAVis.Point
         * @static
         * @param {VRDAVis.IPoint=} [properties] Properties to set
         * @returns {VRDAVis.Point} Point instance
         */
        Point.create = function create(properties) {
            return new Point(properties);
        };

        /**
         * Encodes the specified Point message. Does not implicitly {@link VRDAVis.Point.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.Point
         * @static
         * @param {VRDAVis.IPoint} message Point message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Point.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.y);
            if (message.z != null && Object.hasOwnProperty.call(message, "z"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.z);
            return writer;
        };

        /**
         * Encodes the specified Point message, length delimited. Does not implicitly {@link VRDAVis.Point.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.Point
         * @static
         * @param {VRDAVis.IPoint} message Point message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Point.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Point message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.Point
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.Point} Point
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Point.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.Point();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.x = reader.float();
                    break;
                case 2:
                    message.y = reader.float();
                    break;
                case 3:
                    message.z = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Point message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.Point
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.Point} Point
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Point.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Point message.
         * @function verify
         * @memberof VRDAVis.Point
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Point.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (typeof message.y !== "number")
                    return "y: number expected";
            if (message.z != null && message.hasOwnProperty("z"))
                if (typeof message.z !== "number")
                    return "z: number expected";
            return null;
        };

        /**
         * Creates a Point message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.Point
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.Point} Point
         */
        Point.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.Point)
                return object;
            let message = new $root.VRDAVis.Point();
            if (object.x != null)
                message.x = Number(object.x);
            if (object.y != null)
                message.y = Number(object.y);
            if (object.z != null)
                message.z = Number(object.z);
            return message;
        };

        /**
         * Creates a plain object from a Point message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.Point
         * @static
         * @param {VRDAVis.Point} message Point
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Point.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.y = 0;
                object.z = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
            if (message.z != null && message.hasOwnProperty("z"))
                object.z = options.json && !isFinite(message.z) ? String(message.z) : message.z;
            return object;
        };

        /**
         * Converts this Point to JSON.
         * @function toJSON
         * @memberof VRDAVis.Point
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Point.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Point;
    })();

    VRDAVis.DoublePoint = (function() {

        /**
         * Properties of a DoublePoint.
         * @memberof VRDAVis
         * @interface IDoublePoint
         * @property {number|null} [x] DoublePoint x
         * @property {number|null} [y] DoublePoint y
         * @property {number|null} [z] DoublePoint z
         */

        /**
         * Constructs a new DoublePoint.
         * @memberof VRDAVis
         * @classdesc Represents a DoublePoint.
         * @implements IDoublePoint
         * @constructor
         * @param {VRDAVis.IDoublePoint=} [properties] Properties to set
         */
        function DoublePoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DoublePoint x.
         * @member {number} x
         * @memberof VRDAVis.DoublePoint
         * @instance
         */
        DoublePoint.prototype.x = 0;

        /**
         * DoublePoint y.
         * @member {number} y
         * @memberof VRDAVis.DoublePoint
         * @instance
         */
        DoublePoint.prototype.y = 0;

        /**
         * DoublePoint z.
         * @member {number} z
         * @memberof VRDAVis.DoublePoint
         * @instance
         */
        DoublePoint.prototype.z = 0;

        /**
         * Creates a new DoublePoint instance using the specified properties.
         * @function create
         * @memberof VRDAVis.DoublePoint
         * @static
         * @param {VRDAVis.IDoublePoint=} [properties] Properties to set
         * @returns {VRDAVis.DoublePoint} DoublePoint instance
         */
        DoublePoint.create = function create(properties) {
            return new DoublePoint(properties);
        };

        /**
         * Encodes the specified DoublePoint message. Does not implicitly {@link VRDAVis.DoublePoint.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.DoublePoint
         * @static
         * @param {VRDAVis.IDoublePoint} message DoublePoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DoublePoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.y);
            if (message.z != null && Object.hasOwnProperty.call(message, "z"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.z);
            return writer;
        };

        /**
         * Encodes the specified DoublePoint message, length delimited. Does not implicitly {@link VRDAVis.DoublePoint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.DoublePoint
         * @static
         * @param {VRDAVis.IDoublePoint} message DoublePoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DoublePoint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DoublePoint message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.DoublePoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.DoublePoint} DoublePoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DoublePoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.DoublePoint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.x = reader.double();
                    break;
                case 2:
                    message.y = reader.double();
                    break;
                case 3:
                    message.z = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DoublePoint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.DoublePoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.DoublePoint} DoublePoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DoublePoint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DoublePoint message.
         * @function verify
         * @memberof VRDAVis.DoublePoint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DoublePoint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (typeof message.y !== "number")
                    return "y: number expected";
            if (message.z != null && message.hasOwnProperty("z"))
                if (typeof message.z !== "number")
                    return "z: number expected";
            return null;
        };

        /**
         * Creates a DoublePoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.DoublePoint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.DoublePoint} DoublePoint
         */
        DoublePoint.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.DoublePoint)
                return object;
            let message = new $root.VRDAVis.DoublePoint();
            if (object.x != null)
                message.x = Number(object.x);
            if (object.y != null)
                message.y = Number(object.y);
            if (object.z != null)
                message.z = Number(object.z);
            return message;
        };

        /**
         * Creates a plain object from a DoublePoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.DoublePoint
         * @static
         * @param {VRDAVis.DoublePoint} message DoublePoint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DoublePoint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.y = 0;
                object.z = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
            if (message.z != null && message.hasOwnProperty("z"))
                object.z = options.json && !isFinite(message.z) ? String(message.z) : message.z;
            return object;
        };

        /**
         * Converts this DoublePoint to JSON.
         * @function toJSON
         * @memberof VRDAVis.DoublePoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DoublePoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DoublePoint;
    })();

    VRDAVis.FileInfo = (function() {

        /**
         * Properties of a FileInfo.
         * @memberof VRDAVis
         * @interface IFileInfo
         * @property {string|null} [name] FileInfo name
         * @property {number|Long|null} [size] FileInfo size
         */

        /**
         * Constructs a new FileInfo.
         * @memberof VRDAVis
         * @classdesc Represents a FileInfo.
         * @implements IFileInfo
         * @constructor
         * @param {VRDAVis.IFileInfo=} [properties] Properties to set
         */
        function FileInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileInfo name.
         * @member {string} name
         * @memberof VRDAVis.FileInfo
         * @instance
         */
        FileInfo.prototype.name = "";

        /**
         * FileInfo size.
         * @member {number|Long} size
         * @memberof VRDAVis.FileInfo
         * @instance
         */
        FileInfo.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new FileInfo instance using the specified properties.
         * @function create
         * @memberof VRDAVis.FileInfo
         * @static
         * @param {VRDAVis.IFileInfo=} [properties] Properties to set
         * @returns {VRDAVis.FileInfo} FileInfo instance
         */
        FileInfo.create = function create(properties) {
            return new FileInfo(properties);
        };

        /**
         * Encodes the specified FileInfo message. Does not implicitly {@link VRDAVis.FileInfo.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.FileInfo
         * @static
         * @param {VRDAVis.IFileInfo} message FileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 2, wireType 1 =*/17).sfixed64(message.size);
            return writer;
        };

        /**
         * Encodes the specified FileInfo message, length delimited. Does not implicitly {@link VRDAVis.FileInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.FileInfo
         * @static
         * @param {VRDAVis.IFileInfo} message FileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileInfo message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.FileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.FileInfo} FileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.FileInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.size = reader.sfixed64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.FileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.FileInfo} FileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileInfo message.
         * @function verify
         * @memberof VRDAVis.FileInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            return null;
        };

        /**
         * Creates a FileInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.FileInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.FileInfo} FileInfo
         */
        FileInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.FileInfo)
                return object;
            let message = new $root.VRDAVis.FileInfo();
            if (object.name != null)
                message.name = String(object.name);
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a FileInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.FileInfo
         * @static
         * @param {VRDAVis.FileInfo} message FileInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
            return object;
        };

        /**
         * Converts this FileInfo to JSON.
         * @function toJSON
         * @memberof VRDAVis.FileInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileInfo;
    })();

    VRDAVis.DirectoryInfo = (function() {

        /**
         * Properties of a DirectoryInfo.
         * @memberof VRDAVis
         * @interface IDirectoryInfo
         * @property {string|null} [name] DirectoryInfo name
         * @property {number|null} [itemCount] DirectoryInfo itemCount
         * @property {number|Long|null} [date] DirectoryInfo date
         */

        /**
         * Constructs a new DirectoryInfo.
         * @memberof VRDAVis
         * @classdesc Represents a DirectoryInfo.
         * @implements IDirectoryInfo
         * @constructor
         * @param {VRDAVis.IDirectoryInfo=} [properties] Properties to set
         */
        function DirectoryInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DirectoryInfo name.
         * @member {string} name
         * @memberof VRDAVis.DirectoryInfo
         * @instance
         */
        DirectoryInfo.prototype.name = "";

        /**
         * DirectoryInfo itemCount.
         * @member {number} itemCount
         * @memberof VRDAVis.DirectoryInfo
         * @instance
         */
        DirectoryInfo.prototype.itemCount = 0;

        /**
         * DirectoryInfo date.
         * @member {number|Long} date
         * @memberof VRDAVis.DirectoryInfo
         * @instance
         */
        DirectoryInfo.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new DirectoryInfo instance using the specified properties.
         * @function create
         * @memberof VRDAVis.DirectoryInfo
         * @static
         * @param {VRDAVis.IDirectoryInfo=} [properties] Properties to set
         * @returns {VRDAVis.DirectoryInfo} DirectoryInfo instance
         */
        DirectoryInfo.create = function create(properties) {
            return new DirectoryInfo(properties);
        };

        /**
         * Encodes the specified DirectoryInfo message. Does not implicitly {@link VRDAVis.DirectoryInfo.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.DirectoryInfo
         * @static
         * @param {VRDAVis.IDirectoryInfo} message DirectoryInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DirectoryInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.itemCount != null && Object.hasOwnProperty.call(message, "itemCount"))
                writer.uint32(/* id 2, wireType 5 =*/21).sfixed32(message.itemCount);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 3, wireType 1 =*/25).sfixed64(message.date);
            return writer;
        };

        /**
         * Encodes the specified DirectoryInfo message, length delimited. Does not implicitly {@link VRDAVis.DirectoryInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.DirectoryInfo
         * @static
         * @param {VRDAVis.IDirectoryInfo} message DirectoryInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DirectoryInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DirectoryInfo message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.DirectoryInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.DirectoryInfo} DirectoryInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DirectoryInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.DirectoryInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.itemCount = reader.sfixed32();
                    break;
                case 3:
                    message.date = reader.sfixed64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DirectoryInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.DirectoryInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.DirectoryInfo} DirectoryInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DirectoryInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DirectoryInfo message.
         * @function verify
         * @memberof VRDAVis.DirectoryInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DirectoryInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.itemCount != null && message.hasOwnProperty("itemCount"))
                if (!$util.isInteger(message.itemCount))
                    return "itemCount: integer expected";
            if (message.date != null && message.hasOwnProperty("date"))
                if (!$util.isInteger(message.date) && !(message.date && $util.isInteger(message.date.low) && $util.isInteger(message.date.high)))
                    return "date: integer|Long expected";
            return null;
        };

        /**
         * Creates a DirectoryInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.DirectoryInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.DirectoryInfo} DirectoryInfo
         */
        DirectoryInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.DirectoryInfo)
                return object;
            let message = new $root.VRDAVis.DirectoryInfo();
            if (object.name != null)
                message.name = String(object.name);
            if (object.itemCount != null)
                message.itemCount = object.itemCount | 0;
            if (object.date != null)
                if ($util.Long)
                    (message.date = $util.Long.fromValue(object.date)).unsigned = false;
                else if (typeof object.date === "string")
                    message.date = parseInt(object.date, 10);
                else if (typeof object.date === "number")
                    message.date = object.date;
                else if (typeof object.date === "object")
                    message.date = new $util.LongBits(object.date.low >>> 0, object.date.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a DirectoryInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.DirectoryInfo
         * @static
         * @param {VRDAVis.DirectoryInfo} message DirectoryInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DirectoryInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.itemCount = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.date = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.date = options.longs === String ? "0" : 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.itemCount != null && message.hasOwnProperty("itemCount"))
                object.itemCount = message.itemCount;
            if (message.date != null && message.hasOwnProperty("date"))
                if (typeof message.date === "number")
                    object.date = options.longs === String ? String(message.date) : message.date;
                else
                    object.date = options.longs === String ? $util.Long.prototype.toString.call(message.date) : options.longs === Number ? new $util.LongBits(message.date.low >>> 0, message.date.high >>> 0).toNumber() : message.date;
            return object;
        };

        /**
         * Converts this DirectoryInfo to JSON.
         * @function toJSON
         * @memberof VRDAVis.DirectoryInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DirectoryInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DirectoryInfo;
    })();

    VRDAVis.HeaderEntry = (function() {

        /**
         * Properties of a HeaderEntry.
         * @memberof VRDAVis
         * @interface IHeaderEntry
         * @property {string|null} [name] HeaderEntry name
         * @property {string|null} [value] HeaderEntry value
         * @property {VRDAVis.EntryType|null} [entryType] HeaderEntry entryType
         * @property {number|null} [numericValue] HeaderEntry numericValue
         * @property {string|null} [comment] HeaderEntry comment
         */

        /**
         * Constructs a new HeaderEntry.
         * @memberof VRDAVis
         * @classdesc Represents a HeaderEntry.
         * @implements IHeaderEntry
         * @constructor
         * @param {VRDAVis.IHeaderEntry=} [properties] Properties to set
         */
        function HeaderEntry(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HeaderEntry name.
         * @member {string} name
         * @memberof VRDAVis.HeaderEntry
         * @instance
         */
        HeaderEntry.prototype.name = "";

        /**
         * HeaderEntry value.
         * @member {string} value
         * @memberof VRDAVis.HeaderEntry
         * @instance
         */
        HeaderEntry.prototype.value = "";

        /**
         * HeaderEntry entryType.
         * @member {VRDAVis.EntryType} entryType
         * @memberof VRDAVis.HeaderEntry
         * @instance
         */
        HeaderEntry.prototype.entryType = 0;

        /**
         * HeaderEntry numericValue.
         * @member {number} numericValue
         * @memberof VRDAVis.HeaderEntry
         * @instance
         */
        HeaderEntry.prototype.numericValue = 0;

        /**
         * HeaderEntry comment.
         * @member {string} comment
         * @memberof VRDAVis.HeaderEntry
         * @instance
         */
        HeaderEntry.prototype.comment = "";

        /**
         * Creates a new HeaderEntry instance using the specified properties.
         * @function create
         * @memberof VRDAVis.HeaderEntry
         * @static
         * @param {VRDAVis.IHeaderEntry=} [properties] Properties to set
         * @returns {VRDAVis.HeaderEntry} HeaderEntry instance
         */
        HeaderEntry.create = function create(properties) {
            return new HeaderEntry(properties);
        };

        /**
         * Encodes the specified HeaderEntry message. Does not implicitly {@link VRDAVis.HeaderEntry.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.HeaderEntry
         * @static
         * @param {VRDAVis.IHeaderEntry} message HeaderEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeaderEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.entryType != null && Object.hasOwnProperty.call(message, "entryType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.entryType);
            if (message.numericValue != null && Object.hasOwnProperty.call(message, "numericValue"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.numericValue);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.comment);
            return writer;
        };

        /**
         * Encodes the specified HeaderEntry message, length delimited. Does not implicitly {@link VRDAVis.HeaderEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.HeaderEntry
         * @static
         * @param {VRDAVis.IHeaderEntry} message HeaderEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeaderEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HeaderEntry message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.HeaderEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.HeaderEntry} HeaderEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeaderEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.HeaderEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.entryType = reader.int32();
                    break;
                case 4:
                    message.numericValue = reader.double();
                    break;
                case 5:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HeaderEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.HeaderEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.HeaderEntry} HeaderEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeaderEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HeaderEntry message.
         * @function verify
         * @memberof VRDAVis.HeaderEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HeaderEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            if (message.entryType != null && message.hasOwnProperty("entryType"))
                switch (message.entryType) {
                default:
                    return "entryType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.numericValue != null && message.hasOwnProperty("numericValue"))
                if (typeof message.numericValue !== "number")
                    return "numericValue: number expected";
            if (message.comment != null && message.hasOwnProperty("comment"))
                if (!$util.isString(message.comment))
                    return "comment: string expected";
            return null;
        };

        /**
         * Creates a HeaderEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.HeaderEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.HeaderEntry} HeaderEntry
         */
        HeaderEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.HeaderEntry)
                return object;
            let message = new $root.VRDAVis.HeaderEntry();
            if (object.name != null)
                message.name = String(object.name);
            if (object.value != null)
                message.value = String(object.value);
            switch (object.entryType) {
            case "STRING":
            case 0:
                message.entryType = 0;
                break;
            case "FLOAT":
            case 1:
                message.entryType = 1;
                break;
            case "INT":
            case 2:
                message.entryType = 2;
                break;
            }
            if (object.numericValue != null)
                message.numericValue = Number(object.numericValue);
            if (object.comment != null)
                message.comment = String(object.comment);
            return message;
        };

        /**
         * Creates a plain object from a HeaderEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.HeaderEntry
         * @static
         * @param {VRDAVis.HeaderEntry} message HeaderEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HeaderEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.value = "";
                object.entryType = options.enums === String ? "STRING" : 0;
                object.numericValue = 0;
                object.comment = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            if (message.entryType != null && message.hasOwnProperty("entryType"))
                object.entryType = options.enums === String ? $root.VRDAVis.EntryType[message.entryType] : message.entryType;
            if (message.numericValue != null && message.hasOwnProperty("numericValue"))
                object.numericValue = options.json && !isFinite(message.numericValue) ? String(message.numericValue) : message.numericValue;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            return object;
        };

        /**
         * Converts this HeaderEntry to JSON.
         * @function toJSON
         * @memberof VRDAVis.HeaderEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HeaderEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HeaderEntry;
    })();

    VRDAVis.FileInfoExtended = (function() {

        /**
         * Properties of a FileInfoExtended.
         * @memberof VRDAVis
         * @interface IFileInfoExtended
         * @property {number|null} [dimensions] FileInfoExtended dimensions
         * @property {number|null} [width] FileInfoExtended width
         * @property {number|null} [height] FileInfoExtended height
         * @property {number|null} [length] FileInfoExtended length
         */

        /**
         * Constructs a new FileInfoExtended.
         * @memberof VRDAVis
         * @classdesc Represents a FileInfoExtended.
         * @implements IFileInfoExtended
         * @constructor
         * @param {VRDAVis.IFileInfoExtended=} [properties] Properties to set
         */
        function FileInfoExtended(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileInfoExtended dimensions.
         * @member {number} dimensions
         * @memberof VRDAVis.FileInfoExtended
         * @instance
         */
        FileInfoExtended.prototype.dimensions = 0;

        /**
         * FileInfoExtended width.
         * @member {number} width
         * @memberof VRDAVis.FileInfoExtended
         * @instance
         */
        FileInfoExtended.prototype.width = 0;

        /**
         * FileInfoExtended height.
         * @member {number} height
         * @memberof VRDAVis.FileInfoExtended
         * @instance
         */
        FileInfoExtended.prototype.height = 0;

        /**
         * FileInfoExtended length.
         * @member {number} length
         * @memberof VRDAVis.FileInfoExtended
         * @instance
         */
        FileInfoExtended.prototype.length = 0;

        /**
         * Creates a new FileInfoExtended instance using the specified properties.
         * @function create
         * @memberof VRDAVis.FileInfoExtended
         * @static
         * @param {VRDAVis.IFileInfoExtended=} [properties] Properties to set
         * @returns {VRDAVis.FileInfoExtended} FileInfoExtended instance
         */
        FileInfoExtended.create = function create(properties) {
            return new FileInfoExtended(properties);
        };

        /**
         * Encodes the specified FileInfoExtended message. Does not implicitly {@link VRDAVis.FileInfoExtended.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.FileInfoExtended
         * @static
         * @param {VRDAVis.IFileInfoExtended} message FileInfoExtended message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileInfoExtended.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dimensions != null && Object.hasOwnProperty.call(message, "dimensions"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message.dimensions);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 2, wireType 5 =*/21).sfixed32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 3, wireType 5 =*/29).sfixed32(message.height);
            if (message.length != null && Object.hasOwnProperty.call(message, "length"))
                writer.uint32(/* id 4, wireType 5 =*/37).sfixed32(message.length);
            return writer;
        };

        /**
         * Encodes the specified FileInfoExtended message, length delimited. Does not implicitly {@link VRDAVis.FileInfoExtended.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.FileInfoExtended
         * @static
         * @param {VRDAVis.IFileInfoExtended} message FileInfoExtended message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileInfoExtended.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileInfoExtended message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.FileInfoExtended
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.FileInfoExtended} FileInfoExtended
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileInfoExtended.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.FileInfoExtended();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.dimensions = reader.sfixed32();
                    break;
                case 2:
                    message.width = reader.sfixed32();
                    break;
                case 3:
                    message.height = reader.sfixed32();
                    break;
                case 4:
                    message.length = reader.sfixed32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileInfoExtended message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.FileInfoExtended
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.FileInfoExtended} FileInfoExtended
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileInfoExtended.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileInfoExtended message.
         * @function verify
         * @memberof VRDAVis.FileInfoExtended
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileInfoExtended.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dimensions != null && message.hasOwnProperty("dimensions"))
                if (!$util.isInteger(message.dimensions))
                    return "dimensions: integer expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.length != null && message.hasOwnProperty("length"))
                if (!$util.isInteger(message.length))
                    return "length: integer expected";
            return null;
        };

        /**
         * Creates a FileInfoExtended message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.FileInfoExtended
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.FileInfoExtended} FileInfoExtended
         */
        FileInfoExtended.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.FileInfoExtended)
                return object;
            let message = new $root.VRDAVis.FileInfoExtended();
            if (object.dimensions != null)
                message.dimensions = object.dimensions | 0;
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            if (object.length != null)
                message.length = object.length | 0;
            return message;
        };

        /**
         * Creates a plain object from a FileInfoExtended message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.FileInfoExtended
         * @static
         * @param {VRDAVis.FileInfoExtended} message FileInfoExtended
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileInfoExtended.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dimensions = 0;
                object.width = 0;
                object.height = 0;
                object.length = 0;
            }
            if (message.dimensions != null && message.hasOwnProperty("dimensions"))
                object.dimensions = message.dimensions;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.length != null && message.hasOwnProperty("length"))
                object.length = message.length;
            return object;
        };

        /**
         * Converts this FileInfoExtended to JSON.
         * @function toJSON
         * @memberof VRDAVis.FileInfoExtended
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileInfoExtended.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileInfoExtended;
    })();

    VRDAVis.ImageBounds = (function() {

        /**
         * Properties of an ImageBounds.
         * @memberof VRDAVis
         * @interface IImageBounds
         * @property {number|null} [xMin] ImageBounds xMin
         * @property {number|null} [xMax] ImageBounds xMax
         * @property {number|null} [yMin] ImageBounds yMin
         * @property {number|null} [yMax] ImageBounds yMax
         * @property {number|null} [zMin] ImageBounds zMin
         * @property {number|null} [zMax] ImageBounds zMax
         */

        /**
         * Constructs a new ImageBounds.
         * @memberof VRDAVis
         * @classdesc Represents an ImageBounds.
         * @implements IImageBounds
         * @constructor
         * @param {VRDAVis.IImageBounds=} [properties] Properties to set
         */
        function ImageBounds(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageBounds xMin.
         * @member {number} xMin
         * @memberof VRDAVis.ImageBounds
         * @instance
         */
        ImageBounds.prototype.xMin = 0;

        /**
         * ImageBounds xMax.
         * @member {number} xMax
         * @memberof VRDAVis.ImageBounds
         * @instance
         */
        ImageBounds.prototype.xMax = 0;

        /**
         * ImageBounds yMin.
         * @member {number} yMin
         * @memberof VRDAVis.ImageBounds
         * @instance
         */
        ImageBounds.prototype.yMin = 0;

        /**
         * ImageBounds yMax.
         * @member {number} yMax
         * @memberof VRDAVis.ImageBounds
         * @instance
         */
        ImageBounds.prototype.yMax = 0;

        /**
         * ImageBounds zMin.
         * @member {number} zMin
         * @memberof VRDAVis.ImageBounds
         * @instance
         */
        ImageBounds.prototype.zMin = 0;

        /**
         * ImageBounds zMax.
         * @member {number} zMax
         * @memberof VRDAVis.ImageBounds
         * @instance
         */
        ImageBounds.prototype.zMax = 0;

        /**
         * Creates a new ImageBounds instance using the specified properties.
         * @function create
         * @memberof VRDAVis.ImageBounds
         * @static
         * @param {VRDAVis.IImageBounds=} [properties] Properties to set
         * @returns {VRDAVis.ImageBounds} ImageBounds instance
         */
        ImageBounds.create = function create(properties) {
            return new ImageBounds(properties);
        };

        /**
         * Encodes the specified ImageBounds message. Does not implicitly {@link VRDAVis.ImageBounds.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.ImageBounds
         * @static
         * @param {VRDAVis.IImageBounds} message ImageBounds message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageBounds.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.xMin != null && Object.hasOwnProperty.call(message, "xMin"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message.xMin);
            if (message.xMax != null && Object.hasOwnProperty.call(message, "xMax"))
                writer.uint32(/* id 2, wireType 5 =*/21).sfixed32(message.xMax);
            if (message.yMin != null && Object.hasOwnProperty.call(message, "yMin"))
                writer.uint32(/* id 3, wireType 5 =*/29).sfixed32(message.yMin);
            if (message.yMax != null && Object.hasOwnProperty.call(message, "yMax"))
                writer.uint32(/* id 4, wireType 5 =*/37).sfixed32(message.yMax);
            if (message.zMin != null && Object.hasOwnProperty.call(message, "zMin"))
                writer.uint32(/* id 5, wireType 5 =*/45).sfixed32(message.zMin);
            if (message.zMax != null && Object.hasOwnProperty.call(message, "zMax"))
                writer.uint32(/* id 6, wireType 5 =*/53).sfixed32(message.zMax);
            return writer;
        };

        /**
         * Encodes the specified ImageBounds message, length delimited. Does not implicitly {@link VRDAVis.ImageBounds.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.ImageBounds
         * @static
         * @param {VRDAVis.IImageBounds} message ImageBounds message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageBounds.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageBounds message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.ImageBounds
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.ImageBounds} ImageBounds
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageBounds.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.ImageBounds();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.xMin = reader.sfixed32();
                    break;
                case 2:
                    message.xMax = reader.sfixed32();
                    break;
                case 3:
                    message.yMin = reader.sfixed32();
                    break;
                case 4:
                    message.yMax = reader.sfixed32();
                    break;
                case 5:
                    message.zMin = reader.sfixed32();
                    break;
                case 6:
                    message.zMax = reader.sfixed32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageBounds message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.ImageBounds
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.ImageBounds} ImageBounds
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageBounds.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageBounds message.
         * @function verify
         * @memberof VRDAVis.ImageBounds
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageBounds.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.xMin != null && message.hasOwnProperty("xMin"))
                if (!$util.isInteger(message.xMin))
                    return "xMin: integer expected";
            if (message.xMax != null && message.hasOwnProperty("xMax"))
                if (!$util.isInteger(message.xMax))
                    return "xMax: integer expected";
            if (message.yMin != null && message.hasOwnProperty("yMin"))
                if (!$util.isInteger(message.yMin))
                    return "yMin: integer expected";
            if (message.yMax != null && message.hasOwnProperty("yMax"))
                if (!$util.isInteger(message.yMax))
                    return "yMax: integer expected";
            if (message.zMin != null && message.hasOwnProperty("zMin"))
                if (!$util.isInteger(message.zMin))
                    return "zMin: integer expected";
            if (message.zMax != null && message.hasOwnProperty("zMax"))
                if (!$util.isInteger(message.zMax))
                    return "zMax: integer expected";
            return null;
        };

        /**
         * Creates an ImageBounds message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.ImageBounds
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.ImageBounds} ImageBounds
         */
        ImageBounds.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.ImageBounds)
                return object;
            let message = new $root.VRDAVis.ImageBounds();
            if (object.xMin != null)
                message.xMin = object.xMin | 0;
            if (object.xMax != null)
                message.xMax = object.xMax | 0;
            if (object.yMin != null)
                message.yMin = object.yMin | 0;
            if (object.yMax != null)
                message.yMax = object.yMax | 0;
            if (object.zMin != null)
                message.zMin = object.zMin | 0;
            if (object.zMax != null)
                message.zMax = object.zMax | 0;
            return message;
        };

        /**
         * Creates a plain object from an ImageBounds message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.ImageBounds
         * @static
         * @param {VRDAVis.ImageBounds} message ImageBounds
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageBounds.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.xMin = 0;
                object.xMax = 0;
                object.yMin = 0;
                object.yMax = 0;
                object.zMin = 0;
                object.zMax = 0;
            }
            if (message.xMin != null && message.hasOwnProperty("xMin"))
                object.xMin = message.xMin;
            if (message.xMax != null && message.hasOwnProperty("xMax"))
                object.xMax = message.xMax;
            if (message.yMin != null && message.hasOwnProperty("yMin"))
                object.yMin = message.yMin;
            if (message.yMax != null && message.hasOwnProperty("yMax"))
                object.yMax = message.yMax;
            if (message.zMin != null && message.hasOwnProperty("zMin"))
                object.zMin = message.zMin;
            if (message.zMax != null && message.hasOwnProperty("zMax"))
                object.zMax = message.zMax;
            return object;
        };

        /**
         * Converts this ImageBounds to JSON.
         * @function toJSON
         * @memberof VRDAVis.ImageBounds
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageBounds.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImageBounds;
    })();

    VRDAVis.CubeletParams = (function() {

        /**
         * Properties of a CubeletParams.
         * @memberof VRDAVis
         * @interface ICubeletParams
         * @property {number|null} [layerXY] CubeletParams layerXY
         * @property {number|null} [layerZ] CubeletParams layerZ
         * @property {number|null} [x] CubeletParams x
         * @property {number|null} [y] CubeletParams y
         * @property {number|null} [z] CubeletParams z
         * @property {number|null} [width] CubeletParams width
         * @property {number|null} [height] CubeletParams height
         * @property {number|null} [length] CubeletParams length
         * @property {Uint8Array|null} [compressedVolumeData] CubeletParams compressedVolumeData
         * @property {Array.<number>|null} [volumeData] CubeletParams volumeData
         */

        /**
         * Constructs a new CubeletParams.
         * @memberof VRDAVis
         * @classdesc Represents a CubeletParams.
         * @implements ICubeletParams
         * @constructor
         * @param {VRDAVis.ICubeletParams=} [properties] Properties to set
         */
        function CubeletParams(properties) {
            this.volumeData = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CubeletParams layerXY.
         * @member {number} layerXY
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.layerXY = 0;

        /**
         * CubeletParams layerZ.
         * @member {number} layerZ
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.layerZ = 0;

        /**
         * CubeletParams x.
         * @member {number} x
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.x = 0;

        /**
         * CubeletParams y.
         * @member {number} y
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.y = 0;

        /**
         * CubeletParams z.
         * @member {number} z
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.z = 0;

        /**
         * CubeletParams width.
         * @member {number} width
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.width = 0;

        /**
         * CubeletParams height.
         * @member {number} height
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.height = 0;

        /**
         * CubeletParams length.
         * @member {number} length
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.length = 0;

        /**
         * CubeletParams compressedVolumeData.
         * @member {Uint8Array} compressedVolumeData
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.compressedVolumeData = $util.newBuffer([]);

        /**
         * CubeletParams volumeData.
         * @member {Array.<number>} volumeData
         * @memberof VRDAVis.CubeletParams
         * @instance
         */
        CubeletParams.prototype.volumeData = $util.emptyArray;

        /**
         * Creates a new CubeletParams instance using the specified properties.
         * @function create
         * @memberof VRDAVis.CubeletParams
         * @static
         * @param {VRDAVis.ICubeletParams=} [properties] Properties to set
         * @returns {VRDAVis.CubeletParams} CubeletParams instance
         */
        CubeletParams.create = function create(properties) {
            return new CubeletParams(properties);
        };

        /**
         * Encodes the specified CubeletParams message. Does not implicitly {@link VRDAVis.CubeletParams.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.CubeletParams
         * @static
         * @param {VRDAVis.ICubeletParams} message CubeletParams message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CubeletParams.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.layerXY != null && Object.hasOwnProperty.call(message, "layerXY"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message.layerXY);
            if (message.layerZ != null && Object.hasOwnProperty.call(message, "layerZ"))
                writer.uint32(/* id 2, wireType 5 =*/21).sfixed32(message.layerZ);
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 3, wireType 5 =*/29).sfixed32(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 4, wireType 5 =*/37).sfixed32(message.y);
            if (message.z != null && Object.hasOwnProperty.call(message, "z"))
                writer.uint32(/* id 5, wireType 5 =*/45).sfixed32(message.z);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 6, wireType 5 =*/53).sfixed32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 7, wireType 5 =*/61).sfixed32(message.height);
            if (message.length != null && Object.hasOwnProperty.call(message, "length"))
                writer.uint32(/* id 8, wireType 5 =*/69).sfixed32(message.length);
            if (message.compressedVolumeData != null && Object.hasOwnProperty.call(message, "compressedVolumeData"))
                writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.compressedVolumeData);
            if (message.volumeData != null && message.volumeData.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (let i = 0; i < message.volumeData.length; ++i)
                    writer.float(message.volumeData[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified CubeletParams message, length delimited. Does not implicitly {@link VRDAVis.CubeletParams.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.CubeletParams
         * @static
         * @param {VRDAVis.ICubeletParams} message CubeletParams message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CubeletParams.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CubeletParams message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.CubeletParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.CubeletParams} CubeletParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CubeletParams.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.CubeletParams();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.layerXY = reader.sfixed32();
                    break;
                case 2:
                    message.layerZ = reader.sfixed32();
                    break;
                case 3:
                    message.x = reader.sfixed32();
                    break;
                case 4:
                    message.y = reader.sfixed32();
                    break;
                case 5:
                    message.z = reader.sfixed32();
                    break;
                case 6:
                    message.width = reader.sfixed32();
                    break;
                case 7:
                    message.height = reader.sfixed32();
                    break;
                case 8:
                    message.length = reader.sfixed32();
                    break;
                case 9:
                    message.compressedVolumeData = reader.bytes();
                    break;
                case 10:
                    if (!(message.volumeData && message.volumeData.length))
                        message.volumeData = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.volumeData.push(reader.float());
                    } else
                        message.volumeData.push(reader.float());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CubeletParams message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.CubeletParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.CubeletParams} CubeletParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CubeletParams.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CubeletParams message.
         * @function verify
         * @memberof VRDAVis.CubeletParams
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CubeletParams.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.layerXY != null && message.hasOwnProperty("layerXY"))
                if (!$util.isInteger(message.layerXY))
                    return "layerXY: integer expected";
            if (message.layerZ != null && message.hasOwnProperty("layerZ"))
                if (!$util.isInteger(message.layerZ))
                    return "layerZ: integer expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (!$util.isInteger(message.x))
                    return "x: integer expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (!$util.isInteger(message.y))
                    return "y: integer expected";
            if (message.z != null && message.hasOwnProperty("z"))
                if (!$util.isInteger(message.z))
                    return "z: integer expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.length != null && message.hasOwnProperty("length"))
                if (!$util.isInteger(message.length))
                    return "length: integer expected";
            if (message.compressedVolumeData != null && message.hasOwnProperty("compressedVolumeData"))
                if (!(message.compressedVolumeData && typeof message.compressedVolumeData.length === "number" || $util.isString(message.compressedVolumeData)))
                    return "compressedVolumeData: buffer expected";
            if (message.volumeData != null && message.hasOwnProperty("volumeData")) {
                if (!Array.isArray(message.volumeData))
                    return "volumeData: array expected";
                for (let i = 0; i < message.volumeData.length; ++i)
                    if (typeof message.volumeData[i] !== "number")
                        return "volumeData: number[] expected";
            }
            return null;
        };

        /**
         * Creates a CubeletParams message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.CubeletParams
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.CubeletParams} CubeletParams
         */
        CubeletParams.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.CubeletParams)
                return object;
            let message = new $root.VRDAVis.CubeletParams();
            if (object.layerXY != null)
                message.layerXY = object.layerXY | 0;
            if (object.layerZ != null)
                message.layerZ = object.layerZ | 0;
            if (object.x != null)
                message.x = object.x | 0;
            if (object.y != null)
                message.y = object.y | 0;
            if (object.z != null)
                message.z = object.z | 0;
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            if (object.length != null)
                message.length = object.length | 0;
            if (object.compressedVolumeData != null)
                if (typeof object.compressedVolumeData === "string")
                    $util.base64.decode(object.compressedVolumeData, message.compressedVolumeData = $util.newBuffer($util.base64.length(object.compressedVolumeData)), 0);
                else if (object.compressedVolumeData.length)
                    message.compressedVolumeData = object.compressedVolumeData;
            if (object.volumeData) {
                if (!Array.isArray(object.volumeData))
                    throw TypeError(".VRDAVis.CubeletParams.volumeData: array expected");
                message.volumeData = [];
                for (let i = 0; i < object.volumeData.length; ++i)
                    message.volumeData[i] = Number(object.volumeData[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a CubeletParams message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.CubeletParams
         * @static
         * @param {VRDAVis.CubeletParams} message CubeletParams
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CubeletParams.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.volumeData = [];
            if (options.defaults) {
                object.layerXY = 0;
                object.layerZ = 0;
                object.x = 0;
                object.y = 0;
                object.z = 0;
                object.width = 0;
                object.height = 0;
                object.length = 0;
                if (options.bytes === String)
                    object.compressedVolumeData = "";
                else {
                    object.compressedVolumeData = [];
                    if (options.bytes !== Array)
                        object.compressedVolumeData = $util.newBuffer(object.compressedVolumeData);
                }
            }
            if (message.layerXY != null && message.hasOwnProperty("layerXY"))
                object.layerXY = message.layerXY;
            if (message.layerZ != null && message.hasOwnProperty("layerZ"))
                object.layerZ = message.layerZ;
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = message.y;
            if (message.z != null && message.hasOwnProperty("z"))
                object.z = message.z;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.length != null && message.hasOwnProperty("length"))
                object.length = message.length;
            if (message.compressedVolumeData != null && message.hasOwnProperty("compressedVolumeData"))
                object.compressedVolumeData = options.bytes === String ? $util.base64.encode(message.compressedVolumeData, 0, message.compressedVolumeData.length) : options.bytes === Array ? Array.prototype.slice.call(message.compressedVolumeData) : message.compressedVolumeData;
            if (message.volumeData && message.volumeData.length) {
                object.volumeData = [];
                for (let j = 0; j < message.volumeData.length; ++j)
                    object.volumeData[j] = options.json && !isFinite(message.volumeData[j]) ? String(message.volumeData[j]) : message.volumeData[j];
            }
            return object;
        };

        /**
         * Converts this CubeletParams to JSON.
         * @function toJSON
         * @memberof VRDAVis.CubeletParams
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CubeletParams.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CubeletParams;
    })();

    VRDAVis.StatisticsValue = (function() {

        /**
         * Properties of a StatisticsValue.
         * @memberof VRDAVis
         * @interface IStatisticsValue
         * @property {VRDAVis.StatsType|null} [statsType] StatisticsValue statsType
         * @property {number|null} [value] StatisticsValue value
         * @property {Array.<number>|null} [values] StatisticsValue values
         * @property {Array.<number>|null} [ranges] StatisticsValue ranges
         */

        /**
         * Constructs a new StatisticsValue.
         * @memberof VRDAVis
         * @classdesc Represents a StatisticsValue.
         * @implements IStatisticsValue
         * @constructor
         * @param {VRDAVis.IStatisticsValue=} [properties] Properties to set
         */
        function StatisticsValue(properties) {
            this.values = [];
            this.ranges = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatisticsValue statsType.
         * @member {VRDAVis.StatsType} statsType
         * @memberof VRDAVis.StatisticsValue
         * @instance
         */
        StatisticsValue.prototype.statsType = 0;

        /**
         * StatisticsValue value.
         * @member {number} value
         * @memberof VRDAVis.StatisticsValue
         * @instance
         */
        StatisticsValue.prototype.value = 0;

        /**
         * StatisticsValue values.
         * @member {Array.<number>} values
         * @memberof VRDAVis.StatisticsValue
         * @instance
         */
        StatisticsValue.prototype.values = $util.emptyArray;

        /**
         * StatisticsValue ranges.
         * @member {Array.<number>} ranges
         * @memberof VRDAVis.StatisticsValue
         * @instance
         */
        StatisticsValue.prototype.ranges = $util.emptyArray;

        /**
         * Creates a new StatisticsValue instance using the specified properties.
         * @function create
         * @memberof VRDAVis.StatisticsValue
         * @static
         * @param {VRDAVis.IStatisticsValue=} [properties] Properties to set
         * @returns {VRDAVis.StatisticsValue} StatisticsValue instance
         */
        StatisticsValue.create = function create(properties) {
            return new StatisticsValue(properties);
        };

        /**
         * Encodes the specified StatisticsValue message. Does not implicitly {@link VRDAVis.StatisticsValue.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.StatisticsValue
         * @static
         * @param {VRDAVis.IStatisticsValue} message StatisticsValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatisticsValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statsType != null && Object.hasOwnProperty.call(message, "statsType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.statsType);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.value);
            if (message.values != null && message.values.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.values.length; ++i)
                    writer.sfixed32(message.values[i]);
                writer.ldelim();
            }
            if (message.ranges != null && message.ranges.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (let i = 0; i < message.ranges.length; ++i)
                    writer.float(message.ranges[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified StatisticsValue message, length delimited. Does not implicitly {@link VRDAVis.StatisticsValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.StatisticsValue
         * @static
         * @param {VRDAVis.IStatisticsValue} message StatisticsValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatisticsValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatisticsValue message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.StatisticsValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.StatisticsValue} StatisticsValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatisticsValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.StatisticsValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.statsType = reader.int32();
                    break;
                case 2:
                    message.value = reader.double();
                    break;
                case 3:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.values.push(reader.sfixed32());
                    } else
                        message.values.push(reader.sfixed32());
                    break;
                case 4:
                    if (!(message.ranges && message.ranges.length))
                        message.ranges = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ranges.push(reader.float());
                    } else
                        message.ranges.push(reader.float());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatisticsValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.StatisticsValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.StatisticsValue} StatisticsValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatisticsValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatisticsValue message.
         * @function verify
         * @memberof VRDAVis.StatisticsValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatisticsValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.statsType != null && message.hasOwnProperty("statsType"))
                switch (message.statsType) {
                default:
                    return "statsType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                    break;
                }
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value !== "number")
                    return "value: number expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (let i = 0; i < message.values.length; ++i)
                    if (!$util.isInteger(message.values[i]))
                        return "values: integer[] expected";
            }
            if (message.ranges != null && message.hasOwnProperty("ranges")) {
                if (!Array.isArray(message.ranges))
                    return "ranges: array expected";
                for (let i = 0; i < message.ranges.length; ++i)
                    if (typeof message.ranges[i] !== "number")
                        return "ranges: number[] expected";
            }
            return null;
        };

        /**
         * Creates a StatisticsValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.StatisticsValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.StatisticsValue} StatisticsValue
         */
        StatisticsValue.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.StatisticsValue)
                return object;
            let message = new $root.VRDAVis.StatisticsValue();
            switch (object.statsType) {
            case "NumPixels":
            case 0:
                message.statsType = 0;
                break;
            case "NanCount":
            case 1:
                message.statsType = 1;
                break;
            case "Sum":
            case 2:
                message.statsType = 2;
                break;
            case "FluxDensity":
            case 3:
                message.statsType = 3;
                break;
            case "Mean":
            case 4:
                message.statsType = 4;
                break;
            case "RMS":
            case 5:
                message.statsType = 5;
                break;
            case "Sigma":
            case 6:
                message.statsType = 6;
                break;
            case "SumSq":
            case 7:
                message.statsType = 7;
                break;
            case "Min":
            case 8:
                message.statsType = 8;
                break;
            case "Max":
            case 9:
                message.statsType = 9;
                break;
            case "Extrema":
            case 10:
                message.statsType = 10;
                break;
            case "Blc":
            case 11:
                message.statsType = 11;
                break;
            case "Trc":
            case 12:
                message.statsType = 12;
                break;
            case "MinPos":
            case 13:
                message.statsType = 13;
                break;
            case "MaxPos":
            case 14:
                message.statsType = 14;
                break;
            case "Blcf":
            case 15:
                message.statsType = 15;
                break;
            case "Trcf":
            case 16:
                message.statsType = 16;
                break;
            case "MinPosf":
            case 17:
                message.statsType = 17;
                break;
            case "MaxPosf":
            case 18:
                message.statsType = 18;
                break;
            case "Distribution":
            case 19:
                message.statsType = 19;
                break;
            }
            if (object.value != null)
                message.value = Number(object.value);
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".VRDAVis.StatisticsValue.values: array expected");
                message.values = [];
                for (let i = 0; i < object.values.length; ++i)
                    message.values[i] = object.values[i] | 0;
            }
            if (object.ranges) {
                if (!Array.isArray(object.ranges))
                    throw TypeError(".VRDAVis.StatisticsValue.ranges: array expected");
                message.ranges = [];
                for (let i = 0; i < object.ranges.length; ++i)
                    message.ranges[i] = Number(object.ranges[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a StatisticsValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.StatisticsValue
         * @static
         * @param {VRDAVis.StatisticsValue} message StatisticsValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatisticsValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.values = [];
                object.ranges = [];
            }
            if (options.defaults) {
                object.statsType = options.enums === String ? "NumPixels" : 0;
                object.value = 0;
            }
            if (message.statsType != null && message.hasOwnProperty("statsType"))
                object.statsType = options.enums === String ? $root.VRDAVis.StatsType[message.statsType] : message.statsType;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
            if (message.values && message.values.length) {
                object.values = [];
                for (let j = 0; j < message.values.length; ++j)
                    object.values[j] = message.values[j];
            }
            if (message.ranges && message.ranges.length) {
                object.ranges = [];
                for (let j = 0; j < message.ranges.length; ++j)
                    object.ranges[j] = options.json && !isFinite(message.ranges[j]) ? String(message.ranges[j]) : message.ranges[j];
            }
            return object;
        };

        /**
         * Converts this StatisticsValue to JSON.
         * @function toJSON
         * @memberof VRDAVis.StatisticsValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatisticsValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StatisticsValue;
    })();

    /**
     * EventType enum.
     * @name VRDAVis.EventType
     * @enum {number}
     * @property {number} EMPTY_EVENT=0 EMPTY_EVENT value
     * @property {number} REGISTER_VIEWER=1 REGISTER_VIEWER value
     * @property {number} REGISTER_VIEWER_ACK=2 REGISTER_VIEWER_ACK value
     * @property {number} FILE_LIST_REQUEST=3 FILE_LIST_REQUEST value
     * @property {number} FILE_INFO_REQUEST=4 FILE_INFO_REQUEST value
     * @property {number} FILE_LIST_RESPONSE=5 FILE_LIST_RESPONSE value
     * @property {number} FILE_INFO_RESPONSE=6 FILE_INFO_RESPONSE value
     * @property {number} OPEN_FILE=7 OPEN_FILE value
     * @property {number} OPEN_FILE_ACK=8 OPEN_FILE_ACK value
     * @property {number} CLOSE_FILE=9 CLOSE_FILE value
     * @property {number} ADD_REQUIRED_CUBES=10 ADD_REQUIRED_CUBES value
     * @property {number} REMOVE_REQUIRED_CUBES=11 REMOVE_REQUIRED_CUBES value
     * @property {number} CUBELET_DATA=12 CUBELET_DATA value
     * @property {number} CUBELET_SYNC=13 CUBELET_SYNC value
     * @property {number} RESUME_SESSION=14 RESUME_SESSION value
     * @property {number} RESUME_SESSION_ACK=15 RESUME_SESSION_ACK value
     * @property {number} ERROR_DATA=16 ERROR_DATA value
     * @property {number} REGION_STATS_DATA=17 REGION_STATS_DATA value
     * @property {number} REGION_STATS_REQUEST=18 REGION_STATS_REQUEST value
     * @property {number} SET_REGION_REQUEST=19 SET_REGION_REQUEST value
     * @property {number} SET_REGION_RESPONSE=20 SET_REGION_RESPONSE value
     */
    VRDAVis.EventType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EMPTY_EVENT"] = 0;
        values[valuesById[1] = "REGISTER_VIEWER"] = 1;
        values[valuesById[2] = "REGISTER_VIEWER_ACK"] = 2;
        values[valuesById[3] = "FILE_LIST_REQUEST"] = 3;
        values[valuesById[4] = "FILE_INFO_REQUEST"] = 4;
        values[valuesById[5] = "FILE_LIST_RESPONSE"] = 5;
        values[valuesById[6] = "FILE_INFO_RESPONSE"] = 6;
        values[valuesById[7] = "OPEN_FILE"] = 7;
        values[valuesById[8] = "OPEN_FILE_ACK"] = 8;
        values[valuesById[9] = "CLOSE_FILE"] = 9;
        values[valuesById[10] = "ADD_REQUIRED_CUBES"] = 10;
        values[valuesById[11] = "REMOVE_REQUIRED_CUBES"] = 11;
        values[valuesById[12] = "CUBELET_DATA"] = 12;
        values[valuesById[13] = "CUBELET_SYNC"] = 13;
        values[valuesById[14] = "RESUME_SESSION"] = 14;
        values[valuesById[15] = "RESUME_SESSION_ACK"] = 15;
        values[valuesById[16] = "ERROR_DATA"] = 16;
        values[valuesById[17] = "REGION_STATS_DATA"] = 17;
        values[valuesById[18] = "REGION_STATS_REQUEST"] = 18;
        values[valuesById[19] = "SET_REGION_REQUEST"] = 19;
        values[valuesById[20] = "SET_REGION_RESPONSE"] = 20;
        return values;
    })();

    /**
     * SessionType enum.
     * @name VRDAVis.SessionType
     * @enum {number}
     * @property {number} NEW=0 NEW value
     * @property {number} RESUMED=1 RESUMED value
     */
    VRDAVis.SessionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NEW"] = 0;
        values[valuesById[1] = "RESUMED"] = 1;
        return values;
    })();

    /**
     * FileType enum.
     * @name VRDAVis.FileType
     * @enum {number}
     * @property {number} CASA=0 CASA value
     * @property {number} CRTF=1 CRTF value
     * @property {number} DS9_REG=2 DS9_REG value
     * @property {number} FITS=3 FITS value
     * @property {number} HDF5=4 HDF5 value
     * @property {number} MIRIAD=5 MIRIAD value
     * @property {number} UNKNOWN=6 UNKNOWN value
     */
    VRDAVis.FileType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CASA"] = 0;
        values[valuesById[1] = "CRTF"] = 1;
        values[valuesById[2] = "DS9_REG"] = 2;
        values[valuesById[3] = "FITS"] = 3;
        values[valuesById[4] = "HDF5"] = 4;
        values[valuesById[5] = "MIRIAD"] = 5;
        values[valuesById[6] = "UNKNOWN"] = 6;
        return values;
    })();

    /**
     * CompressionType enum.
     * @name VRDAVis.CompressionType
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} ZFP=1 ZFP value
     * @property {number} SZ=2 SZ value
     */
    VRDAVis.CompressionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "ZFP"] = 1;
        values[valuesById[2] = "SZ"] = 2;
        return values;
    })();

    /**
     * ErrorSeverity enum.
     * @name VRDAVis.ErrorSeverity
     * @enum {number}
     * @property {number} DEBUG=0 DEBUG value
     * @property {number} INFO=1 INFO value
     * @property {number} WARNING=2 WARNING value
     * @property {number} ERROR=3 ERROR value
     * @property {number} CRITICAL=4 CRITICAL value
     */
    VRDAVis.ErrorSeverity = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEBUG"] = 0;
        values[valuesById[1] = "INFO"] = 1;
        values[valuesById[2] = "WARNING"] = 2;
        values[valuesById[3] = "ERROR"] = 3;
        values[valuesById[4] = "CRITICAL"] = 4;
        return values;
    })();

    /**
     * EntryType enum.
     * @name VRDAVis.EntryType
     * @enum {number}
     * @property {number} STRING=0 STRING value
     * @property {number} FLOAT=1 FLOAT value
     * @property {number} INT=2 INT value
     */
    VRDAVis.EntryType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STRING"] = 0;
        values[valuesById[1] = "FLOAT"] = 1;
        values[valuesById[2] = "INT"] = 2;
        return values;
    })();

    /**
     * FileListType enum.
     * @name VRDAVis.FileListType
     * @enum {number}
     * @property {number} Volume=0 Volume value
     */
    VRDAVis.FileListType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Volume"] = 0;
        return values;
    })();

    /**
     * FileListFilterMode enum.
     * @name VRDAVis.FileListFilterMode
     * @enum {number}
     * @property {number} Content=0 Content value
     * @property {number} Extension=1 Extension value
     * @property {number} AllFiles=2 AllFiles value
     */
    VRDAVis.FileListFilterMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Content"] = 0;
        values[valuesById[1] = "Extension"] = 1;
        values[valuesById[2] = "AllFiles"] = 2;
        return values;
    })();

    /**
     * StatsType enum.
     * @name VRDAVis.StatsType
     * @enum {number}
     * @property {number} NumPixels=0 NumPixels value
     * @property {number} NanCount=1 NanCount value
     * @property {number} Sum=2 Sum value
     * @property {number} FluxDensity=3 FluxDensity value
     * @property {number} Mean=4 Mean value
     * @property {number} RMS=5 RMS value
     * @property {number} Sigma=6 Sigma value
     * @property {number} SumSq=7 SumSq value
     * @property {number} Min=8 Min value
     * @property {number} Max=9 Max value
     * @property {number} Extrema=10 Extrema value
     * @property {number} Blc=11 Blc value
     * @property {number} Trc=12 Trc value
     * @property {number} MinPos=13 MinPos value
     * @property {number} MaxPos=14 MaxPos value
     * @property {number} Blcf=15 Blcf value
     * @property {number} Trcf=16 Trcf value
     * @property {number} MinPosf=17 MinPosf value
     * @property {number} MaxPosf=18 MaxPosf value
     * @property {number} Distribution=19 Distribution value
     */
    VRDAVis.StatsType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NumPixels"] = 0;
        values[valuesById[1] = "NanCount"] = 1;
        values[valuesById[2] = "Sum"] = 2;
        values[valuesById[3] = "FluxDensity"] = 3;
        values[valuesById[4] = "Mean"] = 4;
        values[valuesById[5] = "RMS"] = 5;
        values[valuesById[6] = "Sigma"] = 6;
        values[valuesById[7] = "SumSq"] = 7;
        values[valuesById[8] = "Min"] = 8;
        values[valuesById[9] = "Max"] = 9;
        values[valuesById[10] = "Extrema"] = 10;
        values[valuesById[11] = "Blc"] = 11;
        values[valuesById[12] = "Trc"] = 12;
        values[valuesById[13] = "MinPos"] = 13;
        values[valuesById[14] = "MaxPos"] = 14;
        values[valuesById[15] = "Blcf"] = 15;
        values[valuesById[16] = "Trcf"] = 16;
        values[valuesById[17] = "MinPosf"] = 17;
        values[valuesById[18] = "MaxPosf"] = 18;
        values[valuesById[19] = "Distribution"] = 19;
        return values;
    })();

    VRDAVis.CloseFile = (function() {

        /**
         * Properties of a CloseFile.
         * @memberof VRDAVis
         * @interface ICloseFile
         * @property {number|null} [fileId] CloseFile fileId
         */

        /**
         * Constructs a new CloseFile.
         * @memberof VRDAVis
         * @classdesc Represents a CloseFile.
         * @implements ICloseFile
         * @constructor
         * @param {VRDAVis.ICloseFile=} [properties] Properties to set
         */
        function CloseFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CloseFile fileId.
         * @member {number} fileId
         * @memberof VRDAVis.CloseFile
         * @instance
         */
        CloseFile.prototype.fileId = 0;

        /**
         * Creates a new CloseFile instance using the specified properties.
         * @function create
         * @memberof VRDAVis.CloseFile
         * @static
         * @param {VRDAVis.ICloseFile=} [properties] Properties to set
         * @returns {VRDAVis.CloseFile} CloseFile instance
         */
        CloseFile.create = function create(properties) {
            return new CloseFile(properties);
        };

        /**
         * Encodes the specified CloseFile message. Does not implicitly {@link VRDAVis.CloseFile.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.CloseFile
         * @static
         * @param {VRDAVis.ICloseFile} message CloseFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloseFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified CloseFile message, length delimited. Does not implicitly {@link VRDAVis.CloseFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.CloseFile
         * @static
         * @param {VRDAVis.ICloseFile} message CloseFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloseFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CloseFile message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.CloseFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.CloseFile} CloseFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloseFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.CloseFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.sfixed32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CloseFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.CloseFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.CloseFile} CloseFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloseFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CloseFile message.
         * @function verify
         * @memberof VRDAVis.CloseFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CloseFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isInteger(message.fileId))
                    return "fileId: integer expected";
            return null;
        };

        /**
         * Creates a CloseFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.CloseFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.CloseFile} CloseFile
         */
        CloseFile.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.CloseFile)
                return object;
            let message = new $root.VRDAVis.CloseFile();
            if (object.fileId != null)
                message.fileId = object.fileId | 0;
            return message;
        };

        /**
         * Creates a plain object from a CloseFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.CloseFile
         * @static
         * @param {VRDAVis.CloseFile} message CloseFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CloseFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileId = 0;
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this CloseFile to JSON.
         * @function toJSON
         * @memberof VRDAVis.CloseFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CloseFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CloseFile;
    })();

    VRDAVis.AddRequiredCubes = (function() {

        /**
         * Properties of an AddRequiredCubes.
         * @memberof VRDAVis
         * @interface IAddRequiredCubes
         * @property {number|null} [fileId] AddRequiredCubes fileId
         * @property {Array.<string>|null} [cubelets] AddRequiredCubes cubelets
         */

        /**
         * Constructs a new AddRequiredCubes.
         * @memberof VRDAVis
         * @classdesc Represents an AddRequiredCubes.
         * @implements IAddRequiredCubes
         * @constructor
         * @param {VRDAVis.IAddRequiredCubes=} [properties] Properties to set
         */
        function AddRequiredCubes(properties) {
            this.cubelets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddRequiredCubes fileId.
         * @member {number} fileId
         * @memberof VRDAVis.AddRequiredCubes
         * @instance
         */
        AddRequiredCubes.prototype.fileId = 0;

        /**
         * AddRequiredCubes cubelets.
         * @member {Array.<string>} cubelets
         * @memberof VRDAVis.AddRequiredCubes
         * @instance
         */
        AddRequiredCubes.prototype.cubelets = $util.emptyArray;

        /**
         * Creates a new AddRequiredCubes instance using the specified properties.
         * @function create
         * @memberof VRDAVis.AddRequiredCubes
         * @static
         * @param {VRDAVis.IAddRequiredCubes=} [properties] Properties to set
         * @returns {VRDAVis.AddRequiredCubes} AddRequiredCubes instance
         */
        AddRequiredCubes.create = function create(properties) {
            return new AddRequiredCubes(properties);
        };

        /**
         * Encodes the specified AddRequiredCubes message. Does not implicitly {@link VRDAVis.AddRequiredCubes.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.AddRequiredCubes
         * @static
         * @param {VRDAVis.IAddRequiredCubes} message AddRequiredCubes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddRequiredCubes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message.fileId);
            if (message.cubelets != null && message.cubelets.length)
                for (let i = 0; i < message.cubelets.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.cubelets[i]);
            return writer;
        };

        /**
         * Encodes the specified AddRequiredCubes message, length delimited. Does not implicitly {@link VRDAVis.AddRequiredCubes.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.AddRequiredCubes
         * @static
         * @param {VRDAVis.IAddRequiredCubes} message AddRequiredCubes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddRequiredCubes.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddRequiredCubes message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.AddRequiredCubes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.AddRequiredCubes} AddRequiredCubes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddRequiredCubes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.AddRequiredCubes();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.sfixed32();
                    break;
                case 2:
                    if (!(message.cubelets && message.cubelets.length))
                        message.cubelets = [];
                    message.cubelets.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddRequiredCubes message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.AddRequiredCubes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.AddRequiredCubes} AddRequiredCubes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddRequiredCubes.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddRequiredCubes message.
         * @function verify
         * @memberof VRDAVis.AddRequiredCubes
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddRequiredCubes.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isInteger(message.fileId))
                    return "fileId: integer expected";
            if (message.cubelets != null && message.hasOwnProperty("cubelets")) {
                if (!Array.isArray(message.cubelets))
                    return "cubelets: array expected";
                for (let i = 0; i < message.cubelets.length; ++i)
                    if (!$util.isString(message.cubelets[i]))
                        return "cubelets: string[] expected";
            }
            return null;
        };

        /**
         * Creates an AddRequiredCubes message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.AddRequiredCubes
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.AddRequiredCubes} AddRequiredCubes
         */
        AddRequiredCubes.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.AddRequiredCubes)
                return object;
            let message = new $root.VRDAVis.AddRequiredCubes();
            if (object.fileId != null)
                message.fileId = object.fileId | 0;
            if (object.cubelets) {
                if (!Array.isArray(object.cubelets))
                    throw TypeError(".VRDAVis.AddRequiredCubes.cubelets: array expected");
                message.cubelets = [];
                for (let i = 0; i < object.cubelets.length; ++i)
                    message.cubelets[i] = String(object.cubelets[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an AddRequiredCubes message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.AddRequiredCubes
         * @static
         * @param {VRDAVis.AddRequiredCubes} message AddRequiredCubes
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddRequiredCubes.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.cubelets = [];
            if (options.defaults)
                object.fileId = 0;
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.cubelets && message.cubelets.length) {
                object.cubelets = [];
                for (let j = 0; j < message.cubelets.length; ++j)
                    object.cubelets[j] = message.cubelets[j];
            }
            return object;
        };

        /**
         * Converts this AddRequiredCubes to JSON.
         * @function toJSON
         * @memberof VRDAVis.AddRequiredCubes
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddRequiredCubes.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddRequiredCubes;
    })();

    VRDAVis.RemoveRequiredCubelets = (function() {

        /**
         * Properties of a RemoveRequiredCubelets.
         * @memberof VRDAVis
         * @interface IRemoveRequiredCubelets
         * @property {number|null} [fileId] RemoveRequiredCubelets fileId
         * @property {Array.<number>|null} [cubes] RemoveRequiredCubelets cubes
         */

        /**
         * Constructs a new RemoveRequiredCubelets.
         * @memberof VRDAVis
         * @classdesc Represents a RemoveRequiredCubelets.
         * @implements IRemoveRequiredCubelets
         * @constructor
         * @param {VRDAVis.IRemoveRequiredCubelets=} [properties] Properties to set
         */
        function RemoveRequiredCubelets(properties) {
            this.cubes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveRequiredCubelets fileId.
         * @member {number} fileId
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @instance
         */
        RemoveRequiredCubelets.prototype.fileId = 0;

        /**
         * RemoveRequiredCubelets cubes.
         * @member {Array.<number>} cubes
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @instance
         */
        RemoveRequiredCubelets.prototype.cubes = $util.emptyArray;

        /**
         * Creates a new RemoveRequiredCubelets instance using the specified properties.
         * @function create
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @static
         * @param {VRDAVis.IRemoveRequiredCubelets=} [properties] Properties to set
         * @returns {VRDAVis.RemoveRequiredCubelets} RemoveRequiredCubelets instance
         */
        RemoveRequiredCubelets.create = function create(properties) {
            return new RemoveRequiredCubelets(properties);
        };

        /**
         * Encodes the specified RemoveRequiredCubelets message. Does not implicitly {@link VRDAVis.RemoveRequiredCubelets.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @static
         * @param {VRDAVis.IRemoveRequiredCubelets} message RemoveRequiredCubelets message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveRequiredCubelets.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message.fileId);
            if (message.cubes != null && message.cubes.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.cubes.length; ++i)
                    writer.sfixed32(message.cubes[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified RemoveRequiredCubelets message, length delimited. Does not implicitly {@link VRDAVis.RemoveRequiredCubelets.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @static
         * @param {VRDAVis.IRemoveRequiredCubelets} message RemoveRequiredCubelets message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveRequiredCubelets.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveRequiredCubelets message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.RemoveRequiredCubelets} RemoveRequiredCubelets
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveRequiredCubelets.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.RemoveRequiredCubelets();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.sfixed32();
                    break;
                case 2:
                    if (!(message.cubes && message.cubes.length))
                        message.cubes = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cubes.push(reader.sfixed32());
                    } else
                        message.cubes.push(reader.sfixed32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveRequiredCubelets message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.RemoveRequiredCubelets} RemoveRequiredCubelets
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveRequiredCubelets.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveRequiredCubelets message.
         * @function verify
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveRequiredCubelets.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isInteger(message.fileId))
                    return "fileId: integer expected";
            if (message.cubes != null && message.hasOwnProperty("cubes")) {
                if (!Array.isArray(message.cubes))
                    return "cubes: array expected";
                for (let i = 0; i < message.cubes.length; ++i)
                    if (!$util.isInteger(message.cubes[i]))
                        return "cubes: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a RemoveRequiredCubelets message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.RemoveRequiredCubelets} RemoveRequiredCubelets
         */
        RemoveRequiredCubelets.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.RemoveRequiredCubelets)
                return object;
            let message = new $root.VRDAVis.RemoveRequiredCubelets();
            if (object.fileId != null)
                message.fileId = object.fileId | 0;
            if (object.cubes) {
                if (!Array.isArray(object.cubes))
                    throw TypeError(".VRDAVis.RemoveRequiredCubelets.cubes: array expected");
                message.cubes = [];
                for (let i = 0; i < object.cubes.length; ++i)
                    message.cubes[i] = object.cubes[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a RemoveRequiredCubelets message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @static
         * @param {VRDAVis.RemoveRequiredCubelets} message RemoveRequiredCubelets
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveRequiredCubelets.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.cubes = [];
            if (options.defaults)
                object.fileId = 0;
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.cubes && message.cubes.length) {
                object.cubes = [];
                for (let j = 0; j < message.cubes.length; ++j)
                    object.cubes[j] = message.cubes[j];
            }
            return object;
        };

        /**
         * Converts this RemoveRequiredCubelets to JSON.
         * @function toJSON
         * @memberof VRDAVis.RemoveRequiredCubelets
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveRequiredCubelets.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RemoveRequiredCubelets;
    })();

    VRDAVis.OpenFile = (function() {

        /**
         * Properties of an OpenFile.
         * @memberof VRDAVis
         * @interface IOpenFile
         * @property {string|null} [directory] OpenFile directory
         * @property {string|null} [file] OpenFile file
         */

        /**
         * Constructs a new OpenFile.
         * @memberof VRDAVis
         * @classdesc Represents an OpenFile.
         * @implements IOpenFile
         * @constructor
         * @param {VRDAVis.IOpenFile=} [properties] Properties to set
         */
        function OpenFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OpenFile directory.
         * @member {string} directory
         * @memberof VRDAVis.OpenFile
         * @instance
         */
        OpenFile.prototype.directory = "";

        /**
         * OpenFile file.
         * @member {string} file
         * @memberof VRDAVis.OpenFile
         * @instance
         */
        OpenFile.prototype.file = "";

        /**
         * Creates a new OpenFile instance using the specified properties.
         * @function create
         * @memberof VRDAVis.OpenFile
         * @static
         * @param {VRDAVis.IOpenFile=} [properties] Properties to set
         * @returns {VRDAVis.OpenFile} OpenFile instance
         */
        OpenFile.create = function create(properties) {
            return new OpenFile(properties);
        };

        /**
         * Encodes the specified OpenFile message. Does not implicitly {@link VRDAVis.OpenFile.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.OpenFile
         * @static
         * @param {VRDAVis.IOpenFile} message OpenFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.directory != null && Object.hasOwnProperty.call(message, "directory"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.directory);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.file);
            return writer;
        };

        /**
         * Encodes the specified OpenFile message, length delimited. Does not implicitly {@link VRDAVis.OpenFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.OpenFile
         * @static
         * @param {VRDAVis.IOpenFile} message OpenFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OpenFile message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.OpenFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.OpenFile} OpenFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.OpenFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.directory = reader.string();
                    break;
                case 2:
                    message.file = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OpenFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.OpenFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.OpenFile} OpenFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OpenFile message.
         * @function verify
         * @memberof VRDAVis.OpenFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OpenFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.directory != null && message.hasOwnProperty("directory"))
                if (!$util.isString(message.directory))
                    return "directory: string expected";
            if (message.file != null && message.hasOwnProperty("file"))
                if (!$util.isString(message.file))
                    return "file: string expected";
            return null;
        };

        /**
         * Creates an OpenFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.OpenFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.OpenFile} OpenFile
         */
        OpenFile.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.OpenFile)
                return object;
            let message = new $root.VRDAVis.OpenFile();
            if (object.directory != null)
                message.directory = String(object.directory);
            if (object.file != null)
                message.file = String(object.file);
            return message;
        };

        /**
         * Creates a plain object from an OpenFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.OpenFile
         * @static
         * @param {VRDAVis.OpenFile} message OpenFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OpenFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.directory = "";
                object.file = "";
            }
            if (message.directory != null && message.hasOwnProperty("directory"))
                object.directory = message.directory;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = message.file;
            return object;
        };

        /**
         * Converts this OpenFile to JSON.
         * @function toJSON
         * @memberof VRDAVis.OpenFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OpenFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OpenFile;
    })();

    VRDAVis.OpenFileAck = (function() {

        /**
         * Properties of an OpenFileAck.
         * @memberof VRDAVis
         * @interface IOpenFileAck
         * @property {boolean|null} [success] OpenFileAck success
         * @property {string|null} [message] OpenFileAck message
         * @property {VRDAVis.IFileInfoExtended|null} [fileInfo] OpenFileAck fileInfo
         */

        /**
         * Constructs a new OpenFileAck.
         * @memberof VRDAVis
         * @classdesc Represents an OpenFileAck.
         * @implements IOpenFileAck
         * @constructor
         * @param {VRDAVis.IOpenFileAck=} [properties] Properties to set
         */
        function OpenFileAck(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OpenFileAck success.
         * @member {boolean} success
         * @memberof VRDAVis.OpenFileAck
         * @instance
         */
        OpenFileAck.prototype.success = false;

        /**
         * OpenFileAck message.
         * @member {string} message
         * @memberof VRDAVis.OpenFileAck
         * @instance
         */
        OpenFileAck.prototype.message = "";

        /**
         * OpenFileAck fileInfo.
         * @member {VRDAVis.IFileInfoExtended|null|undefined} fileInfo
         * @memberof VRDAVis.OpenFileAck
         * @instance
         */
        OpenFileAck.prototype.fileInfo = null;

        /**
         * Creates a new OpenFileAck instance using the specified properties.
         * @function create
         * @memberof VRDAVis.OpenFileAck
         * @static
         * @param {VRDAVis.IOpenFileAck=} [properties] Properties to set
         * @returns {VRDAVis.OpenFileAck} OpenFileAck instance
         */
        OpenFileAck.create = function create(properties) {
            return new OpenFileAck(properties);
        };

        /**
         * Encodes the specified OpenFileAck message. Does not implicitly {@link VRDAVis.OpenFileAck.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.OpenFileAck
         * @static
         * @param {VRDAVis.IOpenFileAck} message OpenFileAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenFileAck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.fileInfo != null && Object.hasOwnProperty.call(message, "fileInfo"))
                $root.VRDAVis.FileInfoExtended.encode(message.fileInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified OpenFileAck message, length delimited. Does not implicitly {@link VRDAVis.OpenFileAck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.OpenFileAck
         * @static
         * @param {VRDAVis.IOpenFileAck} message OpenFileAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenFileAck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OpenFileAck message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.OpenFileAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.OpenFileAck} OpenFileAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenFileAck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.OpenFileAck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.fileInfo = $root.VRDAVis.FileInfoExtended.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OpenFileAck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.OpenFileAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.OpenFileAck} OpenFileAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenFileAck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OpenFileAck message.
         * @function verify
         * @memberof VRDAVis.OpenFileAck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OpenFileAck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.fileInfo != null && message.hasOwnProperty("fileInfo")) {
                let error = $root.VRDAVis.FileInfoExtended.verify(message.fileInfo);
                if (error)
                    return "fileInfo." + error;
            }
            return null;
        };

        /**
         * Creates an OpenFileAck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.OpenFileAck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.OpenFileAck} OpenFileAck
         */
        OpenFileAck.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.OpenFileAck)
                return object;
            let message = new $root.VRDAVis.OpenFileAck();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.message != null)
                message.message = String(object.message);
            if (object.fileInfo != null) {
                if (typeof object.fileInfo !== "object")
                    throw TypeError(".VRDAVis.OpenFileAck.fileInfo: object expected");
                message.fileInfo = $root.VRDAVis.FileInfoExtended.fromObject(object.fileInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from an OpenFileAck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.OpenFileAck
         * @static
         * @param {VRDAVis.OpenFileAck} message OpenFileAck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OpenFileAck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.success = false;
                object.message = "";
                object.fileInfo = null;
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.fileInfo != null && message.hasOwnProperty("fileInfo"))
                object.fileInfo = $root.VRDAVis.FileInfoExtended.toObject(message.fileInfo, options);
            return object;
        };

        /**
         * Converts this OpenFileAck to JSON.
         * @function toJSON
         * @memberof VRDAVis.OpenFileAck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OpenFileAck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OpenFileAck;
    })();

    VRDAVis.RegisterViewer = (function() {

        /**
         * Properties of a RegisterViewer.
         * @memberof VRDAVis
         * @interface IRegisterViewer
         * @property {number|null} [sessionId] RegisterViewer sessionId
         */

        /**
         * Constructs a new RegisterViewer.
         * @memberof VRDAVis
         * @classdesc Represents a RegisterViewer.
         * @implements IRegisterViewer
         * @constructor
         * @param {VRDAVis.IRegisterViewer=} [properties] Properties to set
         */
        function RegisterViewer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterViewer sessionId.
         * @member {number} sessionId
         * @memberof VRDAVis.RegisterViewer
         * @instance
         */
        RegisterViewer.prototype.sessionId = 0;

        /**
         * Creates a new RegisterViewer instance using the specified properties.
         * @function create
         * @memberof VRDAVis.RegisterViewer
         * @static
         * @param {VRDAVis.IRegisterViewer=} [properties] Properties to set
         * @returns {VRDAVis.RegisterViewer} RegisterViewer instance
         */
        RegisterViewer.create = function create(properties) {
            return new RegisterViewer(properties);
        };

        /**
         * Encodes the specified RegisterViewer message. Does not implicitly {@link VRDAVis.RegisterViewer.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.RegisterViewer
         * @static
         * @param {VRDAVis.IRegisterViewer} message RegisterViewer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterViewer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 5 =*/13).fixed32(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified RegisterViewer message, length delimited. Does not implicitly {@link VRDAVis.RegisterViewer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.RegisterViewer
         * @static
         * @param {VRDAVis.IRegisterViewer} message RegisterViewer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterViewer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegisterViewer message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.RegisterViewer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.RegisterViewer} RegisterViewer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterViewer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.RegisterViewer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sessionId = reader.fixed32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegisterViewer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.RegisterViewer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.RegisterViewer} RegisterViewer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterViewer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegisterViewer message.
         * @function verify
         * @memberof VRDAVis.RegisterViewer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegisterViewer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isInteger(message.sessionId))
                    return "sessionId: integer expected";
            return null;
        };

        /**
         * Creates a RegisterViewer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.RegisterViewer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.RegisterViewer} RegisterViewer
         */
        RegisterViewer.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.RegisterViewer)
                return object;
            let message = new $root.VRDAVis.RegisterViewer();
            if (object.sessionId != null)
                message.sessionId = object.sessionId >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RegisterViewer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.RegisterViewer
         * @static
         * @param {VRDAVis.RegisterViewer} message RegisterViewer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegisterViewer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = 0;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this RegisterViewer to JSON.
         * @function toJSON
         * @memberof VRDAVis.RegisterViewer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegisterViewer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RegisterViewer;
    })();

    VRDAVis.RegisterViewerAck = (function() {

        /**
         * Properties of a RegisterViewerAck.
         * @memberof VRDAVis
         * @interface IRegisterViewerAck
         * @property {number|null} [sessionId] RegisterViewerAck sessionId
         * @property {boolean|null} [success] RegisterViewerAck success
         * @property {string|null} [message] RegisterViewerAck message
         * @property {VRDAVis.SessionType|null} [sessionType] RegisterViewerAck sessionType
         */

        /**
         * Constructs a new RegisterViewerAck.
         * @memberof VRDAVis
         * @classdesc Represents a RegisterViewerAck.
         * @implements IRegisterViewerAck
         * @constructor
         * @param {VRDAVis.IRegisterViewerAck=} [properties] Properties to set
         */
        function RegisterViewerAck(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterViewerAck sessionId.
         * @member {number} sessionId
         * @memberof VRDAVis.RegisterViewerAck
         * @instance
         */
        RegisterViewerAck.prototype.sessionId = 0;

        /**
         * RegisterViewerAck success.
         * @member {boolean} success
         * @memberof VRDAVis.RegisterViewerAck
         * @instance
         */
        RegisterViewerAck.prototype.success = false;

        /**
         * RegisterViewerAck message.
         * @member {string} message
         * @memberof VRDAVis.RegisterViewerAck
         * @instance
         */
        RegisterViewerAck.prototype.message = "";

        /**
         * RegisterViewerAck sessionType.
         * @member {VRDAVis.SessionType} sessionType
         * @memberof VRDAVis.RegisterViewerAck
         * @instance
         */
        RegisterViewerAck.prototype.sessionType = 0;

        /**
         * Creates a new RegisterViewerAck instance using the specified properties.
         * @function create
         * @memberof VRDAVis.RegisterViewerAck
         * @static
         * @param {VRDAVis.IRegisterViewerAck=} [properties] Properties to set
         * @returns {VRDAVis.RegisterViewerAck} RegisterViewerAck instance
         */
        RegisterViewerAck.create = function create(properties) {
            return new RegisterViewerAck(properties);
        };

        /**
         * Encodes the specified RegisterViewerAck message. Does not implicitly {@link VRDAVis.RegisterViewerAck.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.RegisterViewerAck
         * @static
         * @param {VRDAVis.IRegisterViewerAck} message RegisterViewerAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterViewerAck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 5 =*/13).fixed32(message.sessionId);
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.success);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
            if (message.sessionType != null && Object.hasOwnProperty.call(message, "sessionType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sessionType);
            return writer;
        };

        /**
         * Encodes the specified RegisterViewerAck message, length delimited. Does not implicitly {@link VRDAVis.RegisterViewerAck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.RegisterViewerAck
         * @static
         * @param {VRDAVis.IRegisterViewerAck} message RegisterViewerAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegisterViewerAck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegisterViewerAck message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.RegisterViewerAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.RegisterViewerAck} RegisterViewerAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterViewerAck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.RegisterViewerAck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sessionId = reader.fixed32();
                    break;
                case 2:
                    message.success = reader.bool();
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.sessionType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegisterViewerAck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.RegisterViewerAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.RegisterViewerAck} RegisterViewerAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegisterViewerAck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegisterViewerAck message.
         * @function verify
         * @memberof VRDAVis.RegisterViewerAck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegisterViewerAck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isInteger(message.sessionId))
                    return "sessionId: integer expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.sessionType != null && message.hasOwnProperty("sessionType"))
                switch (message.sessionType) {
                default:
                    return "sessionType: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a RegisterViewerAck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.RegisterViewerAck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.RegisterViewerAck} RegisterViewerAck
         */
        RegisterViewerAck.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.RegisterViewerAck)
                return object;
            let message = new $root.VRDAVis.RegisterViewerAck();
            if (object.sessionId != null)
                message.sessionId = object.sessionId >>> 0;
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.message != null)
                message.message = String(object.message);
            switch (object.sessionType) {
            case "NEW":
            case 0:
                message.sessionType = 0;
                break;
            case "RESUMED":
            case 1:
                message.sessionType = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a RegisterViewerAck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.RegisterViewerAck
         * @static
         * @param {VRDAVis.RegisterViewerAck} message RegisterViewerAck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegisterViewerAck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = 0;
                object.success = false;
                object.message = "";
                object.sessionType = options.enums === String ? "NEW" : 0;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.sessionType != null && message.hasOwnProperty("sessionType"))
                object.sessionType = options.enums === String ? $root.VRDAVis.SessionType[message.sessionType] : message.sessionType;
            return object;
        };

        /**
         * Converts this RegisterViewerAck to JSON.
         * @function toJSON
         * @memberof VRDAVis.RegisterViewerAck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegisterViewerAck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RegisterViewerAck;
    })();

    VRDAVis.ImageProperties = (function() {

        /**
         * Properties of an ImageProperties.
         * @memberof VRDAVis
         * @interface IImageProperties
         * @property {string|null} [directory] ImageProperties directory
         * @property {string|null} [file] ImageProperties file
         * @property {number|null} [fileId] ImageProperties fileId
         */

        /**
         * Constructs a new ImageProperties.
         * @memberof VRDAVis
         * @classdesc Represents an ImageProperties.
         * @implements IImageProperties
         * @constructor
         * @param {VRDAVis.IImageProperties=} [properties] Properties to set
         */
        function ImageProperties(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageProperties directory.
         * @member {string} directory
         * @memberof VRDAVis.ImageProperties
         * @instance
         */
        ImageProperties.prototype.directory = "";

        /**
         * ImageProperties file.
         * @member {string} file
         * @memberof VRDAVis.ImageProperties
         * @instance
         */
        ImageProperties.prototype.file = "";

        /**
         * ImageProperties fileId.
         * @member {number} fileId
         * @memberof VRDAVis.ImageProperties
         * @instance
         */
        ImageProperties.prototype.fileId = 0;

        /**
         * Creates a new ImageProperties instance using the specified properties.
         * @function create
         * @memberof VRDAVis.ImageProperties
         * @static
         * @param {VRDAVis.IImageProperties=} [properties] Properties to set
         * @returns {VRDAVis.ImageProperties} ImageProperties instance
         */
        ImageProperties.create = function create(properties) {
            return new ImageProperties(properties);
        };

        /**
         * Encodes the specified ImageProperties message. Does not implicitly {@link VRDAVis.ImageProperties.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.ImageProperties
         * @static
         * @param {VRDAVis.IImageProperties} message ImageProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageProperties.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.directory != null && Object.hasOwnProperty.call(message, "directory"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.directory);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.file);
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 4, wireType 5 =*/37).sfixed32(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified ImageProperties message, length delimited. Does not implicitly {@link VRDAVis.ImageProperties.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.ImageProperties
         * @static
         * @param {VRDAVis.IImageProperties} message ImageProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageProperties.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageProperties message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.ImageProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.ImageProperties} ImageProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageProperties.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.ImageProperties();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.directory = reader.string();
                    break;
                case 2:
                    message.file = reader.string();
                    break;
                case 4:
                    message.fileId = reader.sfixed32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageProperties message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.ImageProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.ImageProperties} ImageProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageProperties.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageProperties message.
         * @function verify
         * @memberof VRDAVis.ImageProperties
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageProperties.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.directory != null && message.hasOwnProperty("directory"))
                if (!$util.isString(message.directory))
                    return "directory: string expected";
            if (message.file != null && message.hasOwnProperty("file"))
                if (!$util.isString(message.file))
                    return "file: string expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isInteger(message.fileId))
                    return "fileId: integer expected";
            return null;
        };

        /**
         * Creates an ImageProperties message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.ImageProperties
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.ImageProperties} ImageProperties
         */
        ImageProperties.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.ImageProperties)
                return object;
            let message = new $root.VRDAVis.ImageProperties();
            if (object.directory != null)
                message.directory = String(object.directory);
            if (object.file != null)
                message.file = String(object.file);
            if (object.fileId != null)
                message.fileId = object.fileId | 0;
            return message;
        };

        /**
         * Creates a plain object from an ImageProperties message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.ImageProperties
         * @static
         * @param {VRDAVis.ImageProperties} message ImageProperties
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageProperties.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.directory = "";
                object.file = "";
                object.fileId = 0;
            }
            if (message.directory != null && message.hasOwnProperty("directory"))
                object.directory = message.directory;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = message.file;
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this ImageProperties to JSON.
         * @function toJSON
         * @memberof VRDAVis.ImageProperties
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageProperties.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImageProperties;
    })();

    VRDAVis.ResumeSession = (function() {

        /**
         * Properties of a ResumeSession.
         * @memberof VRDAVis
         * @interface IResumeSession
         * @property {Array.<VRDAVis.IImageProperties>|null} [images] ResumeSession images
         */

        /**
         * Constructs a new ResumeSession.
         * @memberof VRDAVis
         * @classdesc Represents a ResumeSession.
         * @implements IResumeSession
         * @constructor
         * @param {VRDAVis.IResumeSession=} [properties] Properties to set
         */
        function ResumeSession(properties) {
            this.images = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResumeSession images.
         * @member {Array.<VRDAVis.IImageProperties>} images
         * @memberof VRDAVis.ResumeSession
         * @instance
         */
        ResumeSession.prototype.images = $util.emptyArray;

        /**
         * Creates a new ResumeSession instance using the specified properties.
         * @function create
         * @memberof VRDAVis.ResumeSession
         * @static
         * @param {VRDAVis.IResumeSession=} [properties] Properties to set
         * @returns {VRDAVis.ResumeSession} ResumeSession instance
         */
        ResumeSession.create = function create(properties) {
            return new ResumeSession(properties);
        };

        /**
         * Encodes the specified ResumeSession message. Does not implicitly {@link VRDAVis.ResumeSession.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.ResumeSession
         * @static
         * @param {VRDAVis.IResumeSession} message ResumeSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResumeSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.images != null && message.images.length)
                for (let i = 0; i < message.images.length; ++i)
                    $root.VRDAVis.ImageProperties.encode(message.images[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ResumeSession message, length delimited. Does not implicitly {@link VRDAVis.ResumeSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.ResumeSession
         * @static
         * @param {VRDAVis.IResumeSession} message ResumeSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResumeSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResumeSession message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.ResumeSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.ResumeSession} ResumeSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResumeSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.ResumeSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.images && message.images.length))
                        message.images = [];
                    message.images.push($root.VRDAVis.ImageProperties.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResumeSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.ResumeSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.ResumeSession} ResumeSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResumeSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResumeSession message.
         * @function verify
         * @memberof VRDAVis.ResumeSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResumeSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.images != null && message.hasOwnProperty("images")) {
                if (!Array.isArray(message.images))
                    return "images: array expected";
                for (let i = 0; i < message.images.length; ++i) {
                    let error = $root.VRDAVis.ImageProperties.verify(message.images[i]);
                    if (error)
                        return "images." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ResumeSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.ResumeSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.ResumeSession} ResumeSession
         */
        ResumeSession.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.ResumeSession)
                return object;
            let message = new $root.VRDAVis.ResumeSession();
            if (object.images) {
                if (!Array.isArray(object.images))
                    throw TypeError(".VRDAVis.ResumeSession.images: array expected");
                message.images = [];
                for (let i = 0; i < object.images.length; ++i) {
                    if (typeof object.images[i] !== "object")
                        throw TypeError(".VRDAVis.ResumeSession.images: object expected");
                    message.images[i] = $root.VRDAVis.ImageProperties.fromObject(object.images[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ResumeSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.ResumeSession
         * @static
         * @param {VRDAVis.ResumeSession} message ResumeSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResumeSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.images = [];
            if (message.images && message.images.length) {
                object.images = [];
                for (let j = 0; j < message.images.length; ++j)
                    object.images[j] = $root.VRDAVis.ImageProperties.toObject(message.images[j], options);
            }
            return object;
        };

        /**
         * Converts this ResumeSession to JSON.
         * @function toJSON
         * @memberof VRDAVis.ResumeSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResumeSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResumeSession;
    })();

    VRDAVis.ResumeSessionAck = (function() {

        /**
         * Properties of a ResumeSessionAck.
         * @memberof VRDAVis
         * @interface IResumeSessionAck
         * @property {boolean|null} [success] ResumeSessionAck success
         * @property {string|null} [message] ResumeSessionAck message
         */

        /**
         * Constructs a new ResumeSessionAck.
         * @memberof VRDAVis
         * @classdesc Represents a ResumeSessionAck.
         * @implements IResumeSessionAck
         * @constructor
         * @param {VRDAVis.IResumeSessionAck=} [properties] Properties to set
         */
        function ResumeSessionAck(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResumeSessionAck success.
         * @member {boolean} success
         * @memberof VRDAVis.ResumeSessionAck
         * @instance
         */
        ResumeSessionAck.prototype.success = false;

        /**
         * ResumeSessionAck message.
         * @member {string} message
         * @memberof VRDAVis.ResumeSessionAck
         * @instance
         */
        ResumeSessionAck.prototype.message = "";

        /**
         * Creates a new ResumeSessionAck instance using the specified properties.
         * @function create
         * @memberof VRDAVis.ResumeSessionAck
         * @static
         * @param {VRDAVis.IResumeSessionAck=} [properties] Properties to set
         * @returns {VRDAVis.ResumeSessionAck} ResumeSessionAck instance
         */
        ResumeSessionAck.create = function create(properties) {
            return new ResumeSessionAck(properties);
        };

        /**
         * Encodes the specified ResumeSessionAck message. Does not implicitly {@link VRDAVis.ResumeSessionAck.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.ResumeSessionAck
         * @static
         * @param {VRDAVis.IResumeSessionAck} message ResumeSessionAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResumeSessionAck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified ResumeSessionAck message, length delimited. Does not implicitly {@link VRDAVis.ResumeSessionAck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.ResumeSessionAck
         * @static
         * @param {VRDAVis.IResumeSessionAck} message ResumeSessionAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResumeSessionAck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResumeSessionAck message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.ResumeSessionAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.ResumeSessionAck} ResumeSessionAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResumeSessionAck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.ResumeSessionAck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResumeSessionAck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.ResumeSessionAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.ResumeSessionAck} ResumeSessionAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResumeSessionAck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResumeSessionAck message.
         * @function verify
         * @memberof VRDAVis.ResumeSessionAck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResumeSessionAck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a ResumeSessionAck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.ResumeSessionAck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.ResumeSessionAck} ResumeSessionAck
         */
        ResumeSessionAck.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.ResumeSessionAck)
                return object;
            let message = new $root.VRDAVis.ResumeSessionAck();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a ResumeSessionAck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.ResumeSessionAck
         * @static
         * @param {VRDAVis.ResumeSessionAck} message ResumeSessionAck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResumeSessionAck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.success = false;
                object.message = "";
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this ResumeSessionAck to JSON.
         * @function toJSON
         * @memberof VRDAVis.ResumeSessionAck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResumeSessionAck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResumeSessionAck;
    })();

    VRDAVis.FileInfoRequest = (function() {

        /**
         * Properties of a FileInfoRequest.
         * @memberof VRDAVis
         * @interface IFileInfoRequest
         * @property {string|null} [directory] FileInfoRequest directory
         * @property {string|null} [file] FileInfoRequest file
         */

        /**
         * Constructs a new FileInfoRequest.
         * @memberof VRDAVis
         * @classdesc Represents a FileInfoRequest.
         * @implements IFileInfoRequest
         * @constructor
         * @param {VRDAVis.IFileInfoRequest=} [properties] Properties to set
         */
        function FileInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileInfoRequest directory.
         * @member {string} directory
         * @memberof VRDAVis.FileInfoRequest
         * @instance
         */
        FileInfoRequest.prototype.directory = "";

        /**
         * FileInfoRequest file.
         * @member {string} file
         * @memberof VRDAVis.FileInfoRequest
         * @instance
         */
        FileInfoRequest.prototype.file = "";

        /**
         * Creates a new FileInfoRequest instance using the specified properties.
         * @function create
         * @memberof VRDAVis.FileInfoRequest
         * @static
         * @param {VRDAVis.IFileInfoRequest=} [properties] Properties to set
         * @returns {VRDAVis.FileInfoRequest} FileInfoRequest instance
         */
        FileInfoRequest.create = function create(properties) {
            return new FileInfoRequest(properties);
        };

        /**
         * Encodes the specified FileInfoRequest message. Does not implicitly {@link VRDAVis.FileInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.FileInfoRequest
         * @static
         * @param {VRDAVis.IFileInfoRequest} message FileInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.directory != null && Object.hasOwnProperty.call(message, "directory"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.directory);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.file);
            return writer;
        };

        /**
         * Encodes the specified FileInfoRequest message, length delimited. Does not implicitly {@link VRDAVis.FileInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.FileInfoRequest
         * @static
         * @param {VRDAVis.IFileInfoRequest} message FileInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.FileInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.FileInfoRequest} FileInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.FileInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.directory = reader.string();
                    break;
                case 2:
                    message.file = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.FileInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.FileInfoRequest} FileInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileInfoRequest message.
         * @function verify
         * @memberof VRDAVis.FileInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.directory != null && message.hasOwnProperty("directory"))
                if (!$util.isString(message.directory))
                    return "directory: string expected";
            if (message.file != null && message.hasOwnProperty("file"))
                if (!$util.isString(message.file))
                    return "file: string expected";
            return null;
        };

        /**
         * Creates a FileInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.FileInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.FileInfoRequest} FileInfoRequest
         */
        FileInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.FileInfoRequest)
                return object;
            let message = new $root.VRDAVis.FileInfoRequest();
            if (object.directory != null)
                message.directory = String(object.directory);
            if (object.file != null)
                message.file = String(object.file);
            return message;
        };

        /**
         * Creates a plain object from a FileInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.FileInfoRequest
         * @static
         * @param {VRDAVis.FileInfoRequest} message FileInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileInfoRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.directory = "";
                object.file = "";
            }
            if (message.directory != null && message.hasOwnProperty("directory"))
                object.directory = message.directory;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = message.file;
            return object;
        };

        /**
         * Converts this FileInfoRequest to JSON.
         * @function toJSON
         * @memberof VRDAVis.FileInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileInfoRequest;
    })();

    VRDAVis.FileInfoResponse = (function() {

        /**
         * Properties of a FileInfoResponse.
         * @memberof VRDAVis
         * @interface IFileInfoResponse
         * @property {boolean|null} [success] FileInfoResponse success
         * @property {string|null} [message] FileInfoResponse message
         * @property {VRDAVis.IFileInfo|null} [fileInfo] FileInfoResponse fileInfo
         */

        /**
         * Constructs a new FileInfoResponse.
         * @memberof VRDAVis
         * @classdesc Represents a FileInfoResponse.
         * @implements IFileInfoResponse
         * @constructor
         * @param {VRDAVis.IFileInfoResponse=} [properties] Properties to set
         */
        function FileInfoResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileInfoResponse success.
         * @member {boolean} success
         * @memberof VRDAVis.FileInfoResponse
         * @instance
         */
        FileInfoResponse.prototype.success = false;

        /**
         * FileInfoResponse message.
         * @member {string} message
         * @memberof VRDAVis.FileInfoResponse
         * @instance
         */
        FileInfoResponse.prototype.message = "";

        /**
         * FileInfoResponse fileInfo.
         * @member {VRDAVis.IFileInfo|null|undefined} fileInfo
         * @memberof VRDAVis.FileInfoResponse
         * @instance
         */
        FileInfoResponse.prototype.fileInfo = null;

        /**
         * Creates a new FileInfoResponse instance using the specified properties.
         * @function create
         * @memberof VRDAVis.FileInfoResponse
         * @static
         * @param {VRDAVis.IFileInfoResponse=} [properties] Properties to set
         * @returns {VRDAVis.FileInfoResponse} FileInfoResponse instance
         */
        FileInfoResponse.create = function create(properties) {
            return new FileInfoResponse(properties);
        };

        /**
         * Encodes the specified FileInfoResponse message. Does not implicitly {@link VRDAVis.FileInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.FileInfoResponse
         * @static
         * @param {VRDAVis.IFileInfoResponse} message FileInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.fileInfo != null && Object.hasOwnProperty.call(message, "fileInfo"))
                $root.VRDAVis.FileInfo.encode(message.fileInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FileInfoResponse message, length delimited. Does not implicitly {@link VRDAVis.FileInfoResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.FileInfoResponse
         * @static
         * @param {VRDAVis.IFileInfoResponse} message FileInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.FileInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.FileInfoResponse} FileInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.FileInfoResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.fileInfo = $root.VRDAVis.FileInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileInfoResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.FileInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.FileInfoResponse} FileInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileInfoResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileInfoResponse message.
         * @function verify
         * @memberof VRDAVis.FileInfoResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileInfoResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.fileInfo != null && message.hasOwnProperty("fileInfo")) {
                let error = $root.VRDAVis.FileInfo.verify(message.fileInfo);
                if (error)
                    return "fileInfo." + error;
            }
            return null;
        };

        /**
         * Creates a FileInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.FileInfoResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.FileInfoResponse} FileInfoResponse
         */
        FileInfoResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.FileInfoResponse)
                return object;
            let message = new $root.VRDAVis.FileInfoResponse();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.message != null)
                message.message = String(object.message);
            if (object.fileInfo != null) {
                if (typeof object.fileInfo !== "object")
                    throw TypeError(".VRDAVis.FileInfoResponse.fileInfo: object expected");
                message.fileInfo = $root.VRDAVis.FileInfo.fromObject(object.fileInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a FileInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.FileInfoResponse
         * @static
         * @param {VRDAVis.FileInfoResponse} message FileInfoResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileInfoResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.success = false;
                object.message = "";
                object.fileInfo = null;
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.fileInfo != null && message.hasOwnProperty("fileInfo"))
                object.fileInfo = $root.VRDAVis.FileInfo.toObject(message.fileInfo, options);
            return object;
        };

        /**
         * Converts this FileInfoResponse to JSON.
         * @function toJSON
         * @memberof VRDAVis.FileInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileInfoResponse;
    })();

    VRDAVis.FileListRequest = (function() {

        /**
         * Properties of a FileListRequest.
         * @memberof VRDAVis
         * @interface IFileListRequest
         * @property {string|null} [directory] FileListRequest directory
         */

        /**
         * Constructs a new FileListRequest.
         * @memberof VRDAVis
         * @classdesc Represents a FileListRequest.
         * @implements IFileListRequest
         * @constructor
         * @param {VRDAVis.IFileListRequest=} [properties] Properties to set
         */
        function FileListRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileListRequest directory.
         * @member {string} directory
         * @memberof VRDAVis.FileListRequest
         * @instance
         */
        FileListRequest.prototype.directory = "";

        /**
         * Creates a new FileListRequest instance using the specified properties.
         * @function create
         * @memberof VRDAVis.FileListRequest
         * @static
         * @param {VRDAVis.IFileListRequest=} [properties] Properties to set
         * @returns {VRDAVis.FileListRequest} FileListRequest instance
         */
        FileListRequest.create = function create(properties) {
            return new FileListRequest(properties);
        };

        /**
         * Encodes the specified FileListRequest message. Does not implicitly {@link VRDAVis.FileListRequest.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.FileListRequest
         * @static
         * @param {VRDAVis.IFileListRequest} message FileListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.directory != null && Object.hasOwnProperty.call(message, "directory"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.directory);
            return writer;
        };

        /**
         * Encodes the specified FileListRequest message, length delimited. Does not implicitly {@link VRDAVis.FileListRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.FileListRequest
         * @static
         * @param {VRDAVis.IFileListRequest} message FileListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.FileListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.FileListRequest} FileListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.FileListRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.directory = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileListRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.FileListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.FileListRequest} FileListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileListRequest message.
         * @function verify
         * @memberof VRDAVis.FileListRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.directory != null && message.hasOwnProperty("directory"))
                if (!$util.isString(message.directory))
                    return "directory: string expected";
            return null;
        };

        /**
         * Creates a FileListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.FileListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.FileListRequest} FileListRequest
         */
        FileListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.FileListRequest)
                return object;
            let message = new $root.VRDAVis.FileListRequest();
            if (object.directory != null)
                message.directory = String(object.directory);
            return message;
        };

        /**
         * Creates a plain object from a FileListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.FileListRequest
         * @static
         * @param {VRDAVis.FileListRequest} message FileListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.directory = "";
            if (message.directory != null && message.hasOwnProperty("directory"))
                object.directory = message.directory;
            return object;
        };

        /**
         * Converts this FileListRequest to JSON.
         * @function toJSON
         * @memberof VRDAVis.FileListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileListRequest;
    })();

    VRDAVis.FileListResponse = (function() {

        /**
         * Properties of a FileListResponse.
         * @memberof VRDAVis
         * @interface IFileListResponse
         * @property {boolean|null} [success] FileListResponse success
         * @property {string|null} [message] FileListResponse message
         * @property {string|null} [directory] FileListResponse directory
         * @property {string|null} [parent] FileListResponse parent
         * @property {Array.<VRDAVis.IFileInfo>|null} [files] FileListResponse files
         * @property {boolean|null} [cancel] FileListResponse cancel
         */

        /**
         * Constructs a new FileListResponse.
         * @memberof VRDAVis
         * @classdesc Represents a FileListResponse.
         * @implements IFileListResponse
         * @constructor
         * @param {VRDAVis.IFileListResponse=} [properties] Properties to set
         */
        function FileListResponse(properties) {
            this.files = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileListResponse success.
         * @member {boolean} success
         * @memberof VRDAVis.FileListResponse
         * @instance
         */
        FileListResponse.prototype.success = false;

        /**
         * FileListResponse message.
         * @member {string} message
         * @memberof VRDAVis.FileListResponse
         * @instance
         */
        FileListResponse.prototype.message = "";

        /**
         * FileListResponse directory.
         * @member {string} directory
         * @memberof VRDAVis.FileListResponse
         * @instance
         */
        FileListResponse.prototype.directory = "";

        /**
         * FileListResponse parent.
         * @member {string} parent
         * @memberof VRDAVis.FileListResponse
         * @instance
         */
        FileListResponse.prototype.parent = "";

        /**
         * FileListResponse files.
         * @member {Array.<VRDAVis.IFileInfo>} files
         * @memberof VRDAVis.FileListResponse
         * @instance
         */
        FileListResponse.prototype.files = $util.emptyArray;

        /**
         * FileListResponse cancel.
         * @member {boolean} cancel
         * @memberof VRDAVis.FileListResponse
         * @instance
         */
        FileListResponse.prototype.cancel = false;

        /**
         * Creates a new FileListResponse instance using the specified properties.
         * @function create
         * @memberof VRDAVis.FileListResponse
         * @static
         * @param {VRDAVis.IFileListResponse=} [properties] Properties to set
         * @returns {VRDAVis.FileListResponse} FileListResponse instance
         */
        FileListResponse.create = function create(properties) {
            return new FileListResponse(properties);
        };

        /**
         * Encodes the specified FileListResponse message. Does not implicitly {@link VRDAVis.FileListResponse.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.FileListResponse
         * @static
         * @param {VRDAVis.IFileListResponse} message FileListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.directory != null && Object.hasOwnProperty.call(message, "directory"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.directory);
            if (message.parent != null && Object.hasOwnProperty.call(message, "parent"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.parent);
            if (message.files != null && message.files.length)
                for (let i = 0; i < message.files.length; ++i)
                    $root.VRDAVis.FileInfo.encode(message.files[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.cancel != null && Object.hasOwnProperty.call(message, "cancel"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.cancel);
            return writer;
        };

        /**
         * Encodes the specified FileListResponse message, length delimited. Does not implicitly {@link VRDAVis.FileListResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.FileListResponse
         * @static
         * @param {VRDAVis.IFileListResponse} message FileListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileListResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.FileListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.FileListResponse} FileListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.FileListResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.directory = reader.string();
                    break;
                case 4:
                    message.parent = reader.string();
                    break;
                case 5:
                    if (!(message.files && message.files.length))
                        message.files = [];
                    message.files.push($root.VRDAVis.FileInfo.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.cancel = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileListResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.FileListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.FileListResponse} FileListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileListResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileListResponse message.
         * @function verify
         * @memberof VRDAVis.FileListResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileListResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.directory != null && message.hasOwnProperty("directory"))
                if (!$util.isString(message.directory))
                    return "directory: string expected";
            if (message.parent != null && message.hasOwnProperty("parent"))
                if (!$util.isString(message.parent))
                    return "parent: string expected";
            if (message.files != null && message.hasOwnProperty("files")) {
                if (!Array.isArray(message.files))
                    return "files: array expected";
                for (let i = 0; i < message.files.length; ++i) {
                    let error = $root.VRDAVis.FileInfo.verify(message.files[i]);
                    if (error)
                        return "files." + error;
                }
            }
            if (message.cancel != null && message.hasOwnProperty("cancel"))
                if (typeof message.cancel !== "boolean")
                    return "cancel: boolean expected";
            return null;
        };

        /**
         * Creates a FileListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.FileListResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.FileListResponse} FileListResponse
         */
        FileListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.FileListResponse)
                return object;
            let message = new $root.VRDAVis.FileListResponse();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.message != null)
                message.message = String(object.message);
            if (object.directory != null)
                message.directory = String(object.directory);
            if (object.parent != null)
                message.parent = String(object.parent);
            if (object.files) {
                if (!Array.isArray(object.files))
                    throw TypeError(".VRDAVis.FileListResponse.files: array expected");
                message.files = [];
                for (let i = 0; i < object.files.length; ++i) {
                    if (typeof object.files[i] !== "object")
                        throw TypeError(".VRDAVis.FileListResponse.files: object expected");
                    message.files[i] = $root.VRDAVis.FileInfo.fromObject(object.files[i]);
                }
            }
            if (object.cancel != null)
                message.cancel = Boolean(object.cancel);
            return message;
        };

        /**
         * Creates a plain object from a FileListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.FileListResponse
         * @static
         * @param {VRDAVis.FileListResponse} message FileListResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.files = [];
            if (options.defaults) {
                object.success = false;
                object.message = "";
                object.directory = "";
                object.parent = "";
                object.cancel = false;
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.directory != null && message.hasOwnProperty("directory"))
                object.directory = message.directory;
            if (message.parent != null && message.hasOwnProperty("parent"))
                object.parent = message.parent;
            if (message.files && message.files.length) {
                object.files = [];
                for (let j = 0; j < message.files.length; ++j)
                    object.files[j] = $root.VRDAVis.FileInfo.toObject(message.files[j], options);
            }
            if (message.cancel != null && message.hasOwnProperty("cancel"))
                object.cancel = message.cancel;
            return object;
        };

        /**
         * Converts this FileListResponse to JSON.
         * @function toJSON
         * @memberof VRDAVis.FileListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileListResponse;
    })();

    VRDAVis.StopFileList = (function() {

        /**
         * Properties of a StopFileList.
         * @memberof VRDAVis
         * @interface IStopFileList
         * @property {VRDAVis.FileListType|null} [fileListType] StopFileList fileListType
         */

        /**
         * Constructs a new StopFileList.
         * @memberof VRDAVis
         * @classdesc Represents a StopFileList.
         * @implements IStopFileList
         * @constructor
         * @param {VRDAVis.IStopFileList=} [properties] Properties to set
         */
        function StopFileList(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StopFileList fileListType.
         * @member {VRDAVis.FileListType} fileListType
         * @memberof VRDAVis.StopFileList
         * @instance
         */
        StopFileList.prototype.fileListType = 0;

        /**
         * Creates a new StopFileList instance using the specified properties.
         * @function create
         * @memberof VRDAVis.StopFileList
         * @static
         * @param {VRDAVis.IStopFileList=} [properties] Properties to set
         * @returns {VRDAVis.StopFileList} StopFileList instance
         */
        StopFileList.create = function create(properties) {
            return new StopFileList(properties);
        };

        /**
         * Encodes the specified StopFileList message. Does not implicitly {@link VRDAVis.StopFileList.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.StopFileList
         * @static
         * @param {VRDAVis.IStopFileList} message StopFileList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopFileList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileListType != null && Object.hasOwnProperty.call(message, "fileListType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.fileListType);
            return writer;
        };

        /**
         * Encodes the specified StopFileList message, length delimited. Does not implicitly {@link VRDAVis.StopFileList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.StopFileList
         * @static
         * @param {VRDAVis.IStopFileList} message StopFileList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopFileList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StopFileList message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.StopFileList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.StopFileList} StopFileList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopFileList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.StopFileList();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileListType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StopFileList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.StopFileList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.StopFileList} StopFileList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopFileList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StopFileList message.
         * @function verify
         * @memberof VRDAVis.StopFileList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StopFileList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileListType != null && message.hasOwnProperty("fileListType"))
                switch (message.fileListType) {
                default:
                    return "fileListType: enum value expected";
                case 0:
                    break;
                }
            return null;
        };

        /**
         * Creates a StopFileList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.StopFileList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.StopFileList} StopFileList
         */
        StopFileList.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.StopFileList)
                return object;
            let message = new $root.VRDAVis.StopFileList();
            switch (object.fileListType) {
            case "Volume":
            case 0:
                message.fileListType = 0;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a StopFileList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.StopFileList
         * @static
         * @param {VRDAVis.StopFileList} message StopFileList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StopFileList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileListType = options.enums === String ? "Volume" : 0;
            if (message.fileListType != null && message.hasOwnProperty("fileListType"))
                object.fileListType = options.enums === String ? $root.VRDAVis.FileListType[message.fileListType] : message.fileListType;
            return object;
        };

        /**
         * Converts this StopFileList to JSON.
         * @function toJSON
         * @memberof VRDAVis.StopFileList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StopFileList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StopFileList;
    })();

    VRDAVis.CubeletSync = (function() {

        /**
         * Properties of a CubeletSync.
         * @memberof VRDAVis
         * @interface ICubeletSync
         * @property {number|null} [fileId] CubeletSync fileId
         * @property {number|null} [channel] CubeletSync channel
         * @property {number|null} [stokes] CubeletSync stokes
         * @property {number|null} [animationId] CubeletSync animationId
         * @property {boolean|null} [endSync] CubeletSync endSync
         */

        /**
         * Constructs a new CubeletSync.
         * @memberof VRDAVis
         * @classdesc Represents a CubeletSync.
         * @implements ICubeletSync
         * @constructor
         * @param {VRDAVis.ICubeletSync=} [properties] Properties to set
         */
        function CubeletSync(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CubeletSync fileId.
         * @member {number} fileId
         * @memberof VRDAVis.CubeletSync
         * @instance
         */
        CubeletSync.prototype.fileId = 0;

        /**
         * CubeletSync channel.
         * @member {number} channel
         * @memberof VRDAVis.CubeletSync
         * @instance
         */
        CubeletSync.prototype.channel = 0;

        /**
         * CubeletSync stokes.
         * @member {number} stokes
         * @memberof VRDAVis.CubeletSync
         * @instance
         */
        CubeletSync.prototype.stokes = 0;

        /**
         * CubeletSync animationId.
         * @member {number} animationId
         * @memberof VRDAVis.CubeletSync
         * @instance
         */
        CubeletSync.prototype.animationId = 0;

        /**
         * CubeletSync endSync.
         * @member {boolean} endSync
         * @memberof VRDAVis.CubeletSync
         * @instance
         */
        CubeletSync.prototype.endSync = false;

        /**
         * Creates a new CubeletSync instance using the specified properties.
         * @function create
         * @memberof VRDAVis.CubeletSync
         * @static
         * @param {VRDAVis.ICubeletSync=} [properties] Properties to set
         * @returns {VRDAVis.CubeletSync} CubeletSync instance
         */
        CubeletSync.create = function create(properties) {
            return new CubeletSync(properties);
        };

        /**
         * Encodes the specified CubeletSync message. Does not implicitly {@link VRDAVis.CubeletSync.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.CubeletSync
         * @static
         * @param {VRDAVis.ICubeletSync} message CubeletSync message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CubeletSync.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message.fileId);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 2, wireType 5 =*/21).sfixed32(message.channel);
            if (message.stokes != null && Object.hasOwnProperty.call(message, "stokes"))
                writer.uint32(/* id 3, wireType 5 =*/29).sfixed32(message.stokes);
            if (message.animationId != null && Object.hasOwnProperty.call(message, "animationId"))
                writer.uint32(/* id 4, wireType 5 =*/37).sfixed32(message.animationId);
            if (message.endSync != null && Object.hasOwnProperty.call(message, "endSync"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.endSync);
            return writer;
        };

        /**
         * Encodes the specified CubeletSync message, length delimited. Does not implicitly {@link VRDAVis.CubeletSync.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.CubeletSync
         * @static
         * @param {VRDAVis.ICubeletSync} message CubeletSync message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CubeletSync.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CubeletSync message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.CubeletSync
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.CubeletSync} CubeletSync
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CubeletSync.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.CubeletSync();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.sfixed32();
                    break;
                case 2:
                    message.channel = reader.sfixed32();
                    break;
                case 3:
                    message.stokes = reader.sfixed32();
                    break;
                case 4:
                    message.animationId = reader.sfixed32();
                    break;
                case 5:
                    message.endSync = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CubeletSync message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.CubeletSync
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.CubeletSync} CubeletSync
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CubeletSync.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CubeletSync message.
         * @function verify
         * @memberof VRDAVis.CubeletSync
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CubeletSync.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isInteger(message.fileId))
                    return "fileId: integer expected";
            if (message.channel != null && message.hasOwnProperty("channel"))
                if (!$util.isInteger(message.channel))
                    return "channel: integer expected";
            if (message.stokes != null && message.hasOwnProperty("stokes"))
                if (!$util.isInteger(message.stokes))
                    return "stokes: integer expected";
            if (message.animationId != null && message.hasOwnProperty("animationId"))
                if (!$util.isInteger(message.animationId))
                    return "animationId: integer expected";
            if (message.endSync != null && message.hasOwnProperty("endSync"))
                if (typeof message.endSync !== "boolean")
                    return "endSync: boolean expected";
            return null;
        };

        /**
         * Creates a CubeletSync message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.CubeletSync
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.CubeletSync} CubeletSync
         */
        CubeletSync.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.CubeletSync)
                return object;
            let message = new $root.VRDAVis.CubeletSync();
            if (object.fileId != null)
                message.fileId = object.fileId | 0;
            if (object.channel != null)
                message.channel = object.channel | 0;
            if (object.stokes != null)
                message.stokes = object.stokes | 0;
            if (object.animationId != null)
                message.animationId = object.animationId | 0;
            if (object.endSync != null)
                message.endSync = Boolean(object.endSync);
            return message;
        };

        /**
         * Creates a plain object from a CubeletSync message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.CubeletSync
         * @static
         * @param {VRDAVis.CubeletSync} message CubeletSync
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CubeletSync.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = 0;
                object.channel = 0;
                object.stokes = 0;
                object.animationId = 0;
                object.endSync = false;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.channel != null && message.hasOwnProperty("channel"))
                object.channel = message.channel;
            if (message.stokes != null && message.hasOwnProperty("stokes"))
                object.stokes = message.stokes;
            if (message.animationId != null && message.hasOwnProperty("animationId"))
                object.animationId = message.animationId;
            if (message.endSync != null && message.hasOwnProperty("endSync"))
                object.endSync = message.endSync;
            return object;
        };

        /**
         * Converts this CubeletSync to JSON.
         * @function toJSON
         * @memberof VRDAVis.CubeletSync
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CubeletSync.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CubeletSync;
    })();

    VRDAVis.CubeletData = (function() {

        /**
         * Properties of a CubeletData.
         * @memberof VRDAVis
         * @interface ICubeletData
         * @property {number|null} [fileId] CubeletData fileId
         * @property {Array.<VRDAVis.ICubeletParams>|null} [cubelets] CubeletData cubelets
         */

        /**
         * Constructs a new CubeletData.
         * @memberof VRDAVis
         * @classdesc Represents a CubeletData.
         * @implements ICubeletData
         * @constructor
         * @param {VRDAVis.ICubeletData=} [properties] Properties to set
         */
        function CubeletData(properties) {
            this.cubelets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CubeletData fileId.
         * @member {number} fileId
         * @memberof VRDAVis.CubeletData
         * @instance
         */
        CubeletData.prototype.fileId = 0;

        /**
         * CubeletData cubelets.
         * @member {Array.<VRDAVis.ICubeletParams>} cubelets
         * @memberof VRDAVis.CubeletData
         * @instance
         */
        CubeletData.prototype.cubelets = $util.emptyArray;

        /**
         * Creates a new CubeletData instance using the specified properties.
         * @function create
         * @memberof VRDAVis.CubeletData
         * @static
         * @param {VRDAVis.ICubeletData=} [properties] Properties to set
         * @returns {VRDAVis.CubeletData} CubeletData instance
         */
        CubeletData.create = function create(properties) {
            return new CubeletData(properties);
        };

        /**
         * Encodes the specified CubeletData message. Does not implicitly {@link VRDAVis.CubeletData.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.CubeletData
         * @static
         * @param {VRDAVis.ICubeletData} message CubeletData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CubeletData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message.fileId);
            if (message.cubelets != null && message.cubelets.length)
                for (let i = 0; i < message.cubelets.length; ++i)
                    $root.VRDAVis.CubeletParams.encode(message.cubelets[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CubeletData message, length delimited. Does not implicitly {@link VRDAVis.CubeletData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.CubeletData
         * @static
         * @param {VRDAVis.ICubeletData} message CubeletData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CubeletData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CubeletData message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.CubeletData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.CubeletData} CubeletData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CubeletData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.CubeletData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.sfixed32();
                    break;
                case 2:
                    if (!(message.cubelets && message.cubelets.length))
                        message.cubelets = [];
                    message.cubelets.push($root.VRDAVis.CubeletParams.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CubeletData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.CubeletData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.CubeletData} CubeletData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CubeletData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CubeletData message.
         * @function verify
         * @memberof VRDAVis.CubeletData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CubeletData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isInteger(message.fileId))
                    return "fileId: integer expected";
            if (message.cubelets != null && message.hasOwnProperty("cubelets")) {
                if (!Array.isArray(message.cubelets))
                    return "cubelets: array expected";
                for (let i = 0; i < message.cubelets.length; ++i) {
                    let error = $root.VRDAVis.CubeletParams.verify(message.cubelets[i]);
                    if (error)
                        return "cubelets." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CubeletData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.CubeletData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.CubeletData} CubeletData
         */
        CubeletData.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.CubeletData)
                return object;
            let message = new $root.VRDAVis.CubeletData();
            if (object.fileId != null)
                message.fileId = object.fileId | 0;
            if (object.cubelets) {
                if (!Array.isArray(object.cubelets))
                    throw TypeError(".VRDAVis.CubeletData.cubelets: array expected");
                message.cubelets = [];
                for (let i = 0; i < object.cubelets.length; ++i) {
                    if (typeof object.cubelets[i] !== "object")
                        throw TypeError(".VRDAVis.CubeletData.cubelets: object expected");
                    message.cubelets[i] = $root.VRDAVis.CubeletParams.fromObject(object.cubelets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CubeletData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.CubeletData
         * @static
         * @param {VRDAVis.CubeletData} message CubeletData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CubeletData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.cubelets = [];
            if (options.defaults)
                object.fileId = 0;
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.cubelets && message.cubelets.length) {
                object.cubelets = [];
                for (let j = 0; j < message.cubelets.length; ++j)
                    object.cubelets[j] = $root.VRDAVis.CubeletParams.toObject(message.cubelets[j], options);
            }
            return object;
        };

        /**
         * Converts this CubeletData to JSON.
         * @function toJSON
         * @memberof VRDAVis.CubeletData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CubeletData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CubeletData;
    })();

    VRDAVis.ErrorData = (function() {

        /**
         * Properties of an ErrorData.
         * @memberof VRDAVis
         * @interface IErrorData
         * @property {VRDAVis.ErrorSeverity|null} [severity] ErrorData severity
         * @property {Array.<string>|null} [tags] ErrorData tags
         * @property {string|null} [message] ErrorData message
         * @property {string|null} [data] ErrorData data
         */

        /**
         * Constructs a new ErrorData.
         * @memberof VRDAVis
         * @classdesc Represents an ErrorData.
         * @implements IErrorData
         * @constructor
         * @param {VRDAVis.IErrorData=} [properties] Properties to set
         */
        function ErrorData(properties) {
            this.tags = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ErrorData severity.
         * @member {VRDAVis.ErrorSeverity} severity
         * @memberof VRDAVis.ErrorData
         * @instance
         */
        ErrorData.prototype.severity = 0;

        /**
         * ErrorData tags.
         * @member {Array.<string>} tags
         * @memberof VRDAVis.ErrorData
         * @instance
         */
        ErrorData.prototype.tags = $util.emptyArray;

        /**
         * ErrorData message.
         * @member {string} message
         * @memberof VRDAVis.ErrorData
         * @instance
         */
        ErrorData.prototype.message = "";

        /**
         * ErrorData data.
         * @member {string} data
         * @memberof VRDAVis.ErrorData
         * @instance
         */
        ErrorData.prototype.data = "";

        /**
         * Creates a new ErrorData instance using the specified properties.
         * @function create
         * @memberof VRDAVis.ErrorData
         * @static
         * @param {VRDAVis.IErrorData=} [properties] Properties to set
         * @returns {VRDAVis.ErrorData} ErrorData instance
         */
        ErrorData.create = function create(properties) {
            return new ErrorData(properties);
        };

        /**
         * Encodes the specified ErrorData message. Does not implicitly {@link VRDAVis.ErrorData.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.ErrorData
         * @static
         * @param {VRDAVis.IErrorData} message ErrorData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.severity != null && Object.hasOwnProperty.call(message, "severity"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.severity);
            if (message.tags != null && message.tags.length)
                for (let i = 0; i < message.tags.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.tags[i]);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.data);
            return writer;
        };

        /**
         * Encodes the specified ErrorData message, length delimited. Does not implicitly {@link VRDAVis.ErrorData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.ErrorData
         * @static
         * @param {VRDAVis.IErrorData} message ErrorData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ErrorData message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.ErrorData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.ErrorData} ErrorData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.ErrorData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.severity = reader.int32();
                    break;
                case 2:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push(reader.string());
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.data = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ErrorData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.ErrorData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.ErrorData} ErrorData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ErrorData message.
         * @function verify
         * @memberof VRDAVis.ErrorData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ErrorData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.severity != null && message.hasOwnProperty("severity"))
                switch (message.severity) {
                default:
                    return "severity: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (let i = 0; i < message.tags.length; ++i)
                    if (!$util.isString(message.tags[i]))
                        return "tags: string[] expected";
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!$util.isString(message.data))
                    return "data: string expected";
            return null;
        };

        /**
         * Creates an ErrorData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.ErrorData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.ErrorData} ErrorData
         */
        ErrorData.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.ErrorData)
                return object;
            let message = new $root.VRDAVis.ErrorData();
            switch (object.severity) {
            case "DEBUG":
            case 0:
                message.severity = 0;
                break;
            case "INFO":
            case 1:
                message.severity = 1;
                break;
            case "WARNING":
            case 2:
                message.severity = 2;
                break;
            case "ERROR":
            case 3:
                message.severity = 3;
                break;
            case "CRITICAL":
            case 4:
                message.severity = 4;
                break;
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".VRDAVis.ErrorData.tags: array expected");
                message.tags = [];
                for (let i = 0; i < object.tags.length; ++i)
                    message.tags[i] = String(object.tags[i]);
            }
            if (object.message != null)
                message.message = String(object.message);
            if (object.data != null)
                message.data = String(object.data);
            return message;
        };

        /**
         * Creates a plain object from an ErrorData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.ErrorData
         * @static
         * @param {VRDAVis.ErrorData} message ErrorData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ErrorData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.severity = options.enums === String ? "DEBUG" : 0;
                object.message = "";
                object.data = "";
            }
            if (message.severity != null && message.hasOwnProperty("severity"))
                object.severity = options.enums === String ? $root.VRDAVis.ErrorSeverity[message.severity] : message.severity;
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (let j = 0; j < message.tags.length; ++j)
                    object.tags[j] = message.tags[j];
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = message.data;
            return object;
        };

        /**
         * Converts this ErrorData to JSON.
         * @function toJSON
         * @memberof VRDAVis.ErrorData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ErrorData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ErrorData;
    })();

    VRDAVis.SetRegionRequest = (function() {

        /**
         * Properties of a SetRegionRequest.
         * @memberof VRDAVis
         * @interface ISetRegionRequest
         * @property {Array.<number>|null} [corners] SetRegionRequest corners
         */

        /**
         * Constructs a new SetRegionRequest.
         * @memberof VRDAVis
         * @classdesc Represents a SetRegionRequest.
         * @implements ISetRegionRequest
         * @constructor
         * @param {VRDAVis.ISetRegionRequest=} [properties] Properties to set
         */
        function SetRegionRequest(properties) {
            this.corners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetRegionRequest corners.
         * @member {Array.<number>} corners
         * @memberof VRDAVis.SetRegionRequest
         * @instance
         */
        SetRegionRequest.prototype.corners = $util.emptyArray;

        /**
         * Creates a new SetRegionRequest instance using the specified properties.
         * @function create
         * @memberof VRDAVis.SetRegionRequest
         * @static
         * @param {VRDAVis.ISetRegionRequest=} [properties] Properties to set
         * @returns {VRDAVis.SetRegionRequest} SetRegionRequest instance
         */
        SetRegionRequest.create = function create(properties) {
            return new SetRegionRequest(properties);
        };

        /**
         * Encodes the specified SetRegionRequest message. Does not implicitly {@link VRDAVis.SetRegionRequest.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.SetRegionRequest
         * @static
         * @param {VRDAVis.ISetRegionRequest} message SetRegionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetRegionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.corners != null && message.corners.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.corners.length; ++i)
                    writer.sfixed32(message.corners[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified SetRegionRequest message, length delimited. Does not implicitly {@link VRDAVis.SetRegionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.SetRegionRequest
         * @static
         * @param {VRDAVis.ISetRegionRequest} message SetRegionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetRegionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetRegionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.SetRegionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.SetRegionRequest} SetRegionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetRegionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.SetRegionRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.corners && message.corners.length))
                        message.corners = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.corners.push(reader.sfixed32());
                    } else
                        message.corners.push(reader.sfixed32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetRegionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.SetRegionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.SetRegionRequest} SetRegionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetRegionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetRegionRequest message.
         * @function verify
         * @memberof VRDAVis.SetRegionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetRegionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.corners != null && message.hasOwnProperty("corners")) {
                if (!Array.isArray(message.corners))
                    return "corners: array expected";
                for (let i = 0; i < message.corners.length; ++i)
                    if (!$util.isInteger(message.corners[i]))
                        return "corners: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a SetRegionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.SetRegionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.SetRegionRequest} SetRegionRequest
         */
        SetRegionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.SetRegionRequest)
                return object;
            let message = new $root.VRDAVis.SetRegionRequest();
            if (object.corners) {
                if (!Array.isArray(object.corners))
                    throw TypeError(".VRDAVis.SetRegionRequest.corners: array expected");
                message.corners = [];
                for (let i = 0; i < object.corners.length; ++i)
                    message.corners[i] = object.corners[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a SetRegionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.SetRegionRequest
         * @static
         * @param {VRDAVis.SetRegionRequest} message SetRegionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetRegionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.corners = [];
            if (message.corners && message.corners.length) {
                object.corners = [];
                for (let j = 0; j < message.corners.length; ++j)
                    object.corners[j] = message.corners[j];
            }
            return object;
        };

        /**
         * Converts this SetRegionRequest to JSON.
         * @function toJSON
         * @memberof VRDAVis.SetRegionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetRegionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetRegionRequest;
    })();

    VRDAVis.SetRegionResponse = (function() {

        /**
         * Properties of a SetRegionResponse.
         * @memberof VRDAVis
         * @interface ISetRegionResponse
         * @property {boolean|null} [success] SetRegionResponse success
         */

        /**
         * Constructs a new SetRegionResponse.
         * @memberof VRDAVis
         * @classdesc Represents a SetRegionResponse.
         * @implements ISetRegionResponse
         * @constructor
         * @param {VRDAVis.ISetRegionResponse=} [properties] Properties to set
         */
        function SetRegionResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetRegionResponse success.
         * @member {boolean} success
         * @memberof VRDAVis.SetRegionResponse
         * @instance
         */
        SetRegionResponse.prototype.success = false;

        /**
         * Creates a new SetRegionResponse instance using the specified properties.
         * @function create
         * @memberof VRDAVis.SetRegionResponse
         * @static
         * @param {VRDAVis.ISetRegionResponse=} [properties] Properties to set
         * @returns {VRDAVis.SetRegionResponse} SetRegionResponse instance
         */
        SetRegionResponse.create = function create(properties) {
            return new SetRegionResponse(properties);
        };

        /**
         * Encodes the specified SetRegionResponse message. Does not implicitly {@link VRDAVis.SetRegionResponse.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.SetRegionResponse
         * @static
         * @param {VRDAVis.ISetRegionResponse} message SetRegionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetRegionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            return writer;
        };

        /**
         * Encodes the specified SetRegionResponse message, length delimited. Does not implicitly {@link VRDAVis.SetRegionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.SetRegionResponse
         * @static
         * @param {VRDAVis.ISetRegionResponse} message SetRegionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetRegionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetRegionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.SetRegionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.SetRegionResponse} SetRegionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetRegionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.SetRegionResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetRegionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.SetRegionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.SetRegionResponse} SetRegionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetRegionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetRegionResponse message.
         * @function verify
         * @memberof VRDAVis.SetRegionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetRegionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            return null;
        };

        /**
         * Creates a SetRegionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.SetRegionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.SetRegionResponse} SetRegionResponse
         */
        SetRegionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.SetRegionResponse)
                return object;
            let message = new $root.VRDAVis.SetRegionResponse();
            if (object.success != null)
                message.success = Boolean(object.success);
            return message;
        };

        /**
         * Creates a plain object from a SetRegionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.SetRegionResponse
         * @static
         * @param {VRDAVis.SetRegionResponse} message SetRegionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetRegionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.success = false;
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            return object;
        };

        /**
         * Converts this SetRegionResponse to JSON.
         * @function toJSON
         * @memberof VRDAVis.SetRegionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetRegionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetRegionResponse;
    })();

    VRDAVis.RegionStatsRequest = (function() {

        /**
         * Properties of a RegionStatsRequest.
         * @memberof VRDAVis
         * @interface IRegionStatsRequest
         * @property {Array.<VRDAVis.StatsType>|null} [statistics] RegionStatsRequest statistics
         */

        /**
         * Constructs a new RegionStatsRequest.
         * @memberof VRDAVis
         * @classdesc Represents a RegionStatsRequest.
         * @implements IRegionStatsRequest
         * @constructor
         * @param {VRDAVis.IRegionStatsRequest=} [properties] Properties to set
         */
        function RegionStatsRequest(properties) {
            this.statistics = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegionStatsRequest statistics.
         * @member {Array.<VRDAVis.StatsType>} statistics
         * @memberof VRDAVis.RegionStatsRequest
         * @instance
         */
        RegionStatsRequest.prototype.statistics = $util.emptyArray;

        /**
         * Creates a new RegionStatsRequest instance using the specified properties.
         * @function create
         * @memberof VRDAVis.RegionStatsRequest
         * @static
         * @param {VRDAVis.IRegionStatsRequest=} [properties] Properties to set
         * @returns {VRDAVis.RegionStatsRequest} RegionStatsRequest instance
         */
        RegionStatsRequest.create = function create(properties) {
            return new RegionStatsRequest(properties);
        };

        /**
         * Encodes the specified RegionStatsRequest message. Does not implicitly {@link VRDAVis.RegionStatsRequest.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.RegionStatsRequest
         * @static
         * @param {VRDAVis.IRegionStatsRequest} message RegionStatsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegionStatsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statistics != null && message.statistics.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.statistics.length; ++i)
                    writer.int32(message.statistics[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified RegionStatsRequest message, length delimited. Does not implicitly {@link VRDAVis.RegionStatsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.RegionStatsRequest
         * @static
         * @param {VRDAVis.IRegionStatsRequest} message RegionStatsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegionStatsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegionStatsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.RegionStatsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.RegionStatsRequest} RegionStatsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegionStatsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.RegionStatsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.statistics && message.statistics.length))
                        message.statistics = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.statistics.push(reader.int32());
                    } else
                        message.statistics.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegionStatsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.RegionStatsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.RegionStatsRequest} RegionStatsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegionStatsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegionStatsRequest message.
         * @function verify
         * @memberof VRDAVis.RegionStatsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegionStatsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.statistics != null && message.hasOwnProperty("statistics")) {
                if (!Array.isArray(message.statistics))
                    return "statistics: array expected";
                for (let i = 0; i < message.statistics.length; ++i)
                    switch (message.statistics[i]) {
                    default:
                        return "statistics: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a RegionStatsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.RegionStatsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.RegionStatsRequest} RegionStatsRequest
         */
        RegionStatsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.RegionStatsRequest)
                return object;
            let message = new $root.VRDAVis.RegionStatsRequest();
            if (object.statistics) {
                if (!Array.isArray(object.statistics))
                    throw TypeError(".VRDAVis.RegionStatsRequest.statistics: array expected");
                message.statistics = [];
                for (let i = 0; i < object.statistics.length; ++i)
                    switch (object.statistics[i]) {
                    default:
                    case "NumPixels":
                    case 0:
                        message.statistics[i] = 0;
                        break;
                    case "NanCount":
                    case 1:
                        message.statistics[i] = 1;
                        break;
                    case "Sum":
                    case 2:
                        message.statistics[i] = 2;
                        break;
                    case "FluxDensity":
                    case 3:
                        message.statistics[i] = 3;
                        break;
                    case "Mean":
                    case 4:
                        message.statistics[i] = 4;
                        break;
                    case "RMS":
                    case 5:
                        message.statistics[i] = 5;
                        break;
                    case "Sigma":
                    case 6:
                        message.statistics[i] = 6;
                        break;
                    case "SumSq":
                    case 7:
                        message.statistics[i] = 7;
                        break;
                    case "Min":
                    case 8:
                        message.statistics[i] = 8;
                        break;
                    case "Max":
                    case 9:
                        message.statistics[i] = 9;
                        break;
                    case "Extrema":
                    case 10:
                        message.statistics[i] = 10;
                        break;
                    case "Blc":
                    case 11:
                        message.statistics[i] = 11;
                        break;
                    case "Trc":
                    case 12:
                        message.statistics[i] = 12;
                        break;
                    case "MinPos":
                    case 13:
                        message.statistics[i] = 13;
                        break;
                    case "MaxPos":
                    case 14:
                        message.statistics[i] = 14;
                        break;
                    case "Blcf":
                    case 15:
                        message.statistics[i] = 15;
                        break;
                    case "Trcf":
                    case 16:
                        message.statistics[i] = 16;
                        break;
                    case "MinPosf":
                    case 17:
                        message.statistics[i] = 17;
                        break;
                    case "MaxPosf":
                    case 18:
                        message.statistics[i] = 18;
                        break;
                    case "Distribution":
                    case 19:
                        message.statistics[i] = 19;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a RegionStatsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.RegionStatsRequest
         * @static
         * @param {VRDAVis.RegionStatsRequest} message RegionStatsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegionStatsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.statistics = [];
            if (message.statistics && message.statistics.length) {
                object.statistics = [];
                for (let j = 0; j < message.statistics.length; ++j)
                    object.statistics[j] = options.enums === String ? $root.VRDAVis.StatsType[message.statistics[j]] : message.statistics[j];
            }
            return object;
        };

        /**
         * Converts this RegionStatsRequest to JSON.
         * @function toJSON
         * @memberof VRDAVis.RegionStatsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegionStatsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RegionStatsRequest;
    })();

    VRDAVis.RegionStatsData = (function() {

        /**
         * Properties of a RegionStatsData.
         * @memberof VRDAVis
         * @interface IRegionStatsData
         * @property {Array.<VRDAVis.IStatisticsValue>|null} [statistics] RegionStatsData statistics
         */

        /**
         * Constructs a new RegionStatsData.
         * @memberof VRDAVis
         * @classdesc Represents a RegionStatsData.
         * @implements IRegionStatsData
         * @constructor
         * @param {VRDAVis.IRegionStatsData=} [properties] Properties to set
         */
        function RegionStatsData(properties) {
            this.statistics = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegionStatsData statistics.
         * @member {Array.<VRDAVis.IStatisticsValue>} statistics
         * @memberof VRDAVis.RegionStatsData
         * @instance
         */
        RegionStatsData.prototype.statistics = $util.emptyArray;

        /**
         * Creates a new RegionStatsData instance using the specified properties.
         * @function create
         * @memberof VRDAVis.RegionStatsData
         * @static
         * @param {VRDAVis.IRegionStatsData=} [properties] Properties to set
         * @returns {VRDAVis.RegionStatsData} RegionStatsData instance
         */
        RegionStatsData.create = function create(properties) {
            return new RegionStatsData(properties);
        };

        /**
         * Encodes the specified RegionStatsData message. Does not implicitly {@link VRDAVis.RegionStatsData.verify|verify} messages.
         * @function encode
         * @memberof VRDAVis.RegionStatsData
         * @static
         * @param {VRDAVis.IRegionStatsData} message RegionStatsData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegionStatsData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statistics != null && message.statistics.length)
                for (let i = 0; i < message.statistics.length; ++i)
                    $root.VRDAVis.StatisticsValue.encode(message.statistics[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RegionStatsData message, length delimited. Does not implicitly {@link VRDAVis.RegionStatsData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VRDAVis.RegionStatsData
         * @static
         * @param {VRDAVis.IRegionStatsData} message RegionStatsData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegionStatsData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegionStatsData message from the specified reader or buffer.
         * @function decode
         * @memberof VRDAVis.RegionStatsData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VRDAVis.RegionStatsData} RegionStatsData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegionStatsData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VRDAVis.RegionStatsData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.statistics && message.statistics.length))
                        message.statistics = [];
                    message.statistics.push($root.VRDAVis.StatisticsValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegionStatsData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VRDAVis.RegionStatsData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VRDAVis.RegionStatsData} RegionStatsData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegionStatsData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegionStatsData message.
         * @function verify
         * @memberof VRDAVis.RegionStatsData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegionStatsData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.statistics != null && message.hasOwnProperty("statistics")) {
                if (!Array.isArray(message.statistics))
                    return "statistics: array expected";
                for (let i = 0; i < message.statistics.length; ++i) {
                    let error = $root.VRDAVis.StatisticsValue.verify(message.statistics[i]);
                    if (error)
                        return "statistics." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RegionStatsData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VRDAVis.RegionStatsData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VRDAVis.RegionStatsData} RegionStatsData
         */
        RegionStatsData.fromObject = function fromObject(object) {
            if (object instanceof $root.VRDAVis.RegionStatsData)
                return object;
            let message = new $root.VRDAVis.RegionStatsData();
            if (object.statistics) {
                if (!Array.isArray(object.statistics))
                    throw TypeError(".VRDAVis.RegionStatsData.statistics: array expected");
                message.statistics = [];
                for (let i = 0; i < object.statistics.length; ++i) {
                    if (typeof object.statistics[i] !== "object")
                        throw TypeError(".VRDAVis.RegionStatsData.statistics: object expected");
                    message.statistics[i] = $root.VRDAVis.StatisticsValue.fromObject(object.statistics[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RegionStatsData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VRDAVis.RegionStatsData
         * @static
         * @param {VRDAVis.RegionStatsData} message RegionStatsData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegionStatsData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.statistics = [];
            if (message.statistics && message.statistics.length) {
                object.statistics = [];
                for (let j = 0; j < message.statistics.length; ++j)
                    object.statistics[j] = $root.VRDAVis.StatisticsValue.toObject(message.statistics[j], options);
            }
            return object;
        };

        /**
         * Converts this RegionStatsData to JSON.
         * @function toJSON
         * @memberof VRDAVis.RegionStatsData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegionStatsData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RegionStatsData;
    })();

    return VRDAVis;
})();

export { $root as default };
